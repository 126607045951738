import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import api from "services/api";
import toast from "react-hot-toast";
import ModalChangeStatus from "../ModalChangeStatus";

const SwitchStatus = (props) => {
  const { data, handleChange, index } = props;
  const [openModal, setOpenModal] = useState(false);
  const [checked, setChecked] = useState(data.status == 1 ? true : false);
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: 'green',
          ...theme.applyStyles('dark', {
            backgroundColor: '#177ddc',
          }),
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
      ...theme.applyStyles('dark', {
        backgroundColor: 'rgba(255,255,255,.35)',
      }),
    },
  }));
  const updateStatus = async (event) => {
    try {
      if (!event) return;
      const status = data.status == 1 ? 0 : 1;
      const response = await api.put(`/webhooks/${data.id}`, { status });
      if (response.status == 200) {
        data.status = response.data.status;
        setChecked(data.status == 1 ? true : false);
        handleChange({ data, index });
      }
    } catch (error) {
      toast.error("Ocorreu um erro ao atualizar o status!");
    }
  }
  return (
    <>
      <AntSwitch checked={checked} inputProps={{ 'aria-label': 'ant design' }} onChange={setOpenModal} />
      <ModalChangeStatus open={openModal} onClose={() => setOpenModal(false)}  handleChange={updateStatus}/>
    </>
  )
}

export default SwitchStatus;