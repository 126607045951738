import { put, takeLatest } from "redux-saga/effects";
import api from "services/api";
import { TaskTypes, TaskActions } from "store/ducks/tasks";
import { GET, PUT } from "utils/constants/verbs";
import { TASKS, UPDATE_TASK } from "utils/constants";

export function* fetchTasks({ params }) {
  try {
    const { data } = yield api({
      method: GET,
      url: TASKS,
      params,
    });

    yield put(TaskActions.fetchTasksSuccess(data));
  } catch (error) {
    console.log(error);
  }
}

export function* editTask({ id, payload }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: PUT,
        url: UPDATE_TASK(id),
        data: payload,
      });

      yield put(TaskActions.editTaskSuccess(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchSagas() {
  yield takeLatest(TaskTypes.EDIT_TASK, editTask);
  yield takeLatest(TaskTypes.FETCH_TASKS, fetchTasks);
}
