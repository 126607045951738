import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputForm from "components/core/InputForm";
import { Subtitle } from "components/core/Typography";
import { Container } from "components/core/Grid";
import SelectForm from "components/core/SelectForm";
import { Form, InputHidden } from "components/core/Form";
import { mapToSelectOptions } from "helpers";

const EditRPS = ({ onSubmit, submitRef, options }) => {
  const [invoiceType, setInvoiceType] = useState(1);
  const { t: translate } = useTranslation();
  const { register, handleSubmit, errors } = useForm({ mode: "onBlur" });

  const handleChange = (event) => {
    const { value } = event.target;
    setInvoiceType(value);
  };
  const emptyField = [{ id: 4, name: "Nenhum tipo atribuido" }];
  return (
    <>
      <Container maxWidth="md">
        <Subtitle>{translate("information")}</Subtitle>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <SelectForm
            register={register}
            name="idInvoiceType"
            options={
              options.length > 0 ? mapToSelectOptions(options) : mapToSelectOptions(emptyField)
            }
            label="Tipo da nota fiscal"
            handleChange={handleChange}
            errorMsg={errors.tipoNotaFiscal?.message}
          />
          <InputForm
            register={register}
            type="text"
            name="invoiceSeries"
            label="série"
            errorMsg={errors.serie?.message}
          />
          <InputForm
            register={register}
            type="text"
            name="invoiceSerialNumber"
            label="Número Sequencial da Nota"
            errorMsg={errors.invoiceSerialNumber?.message}
          />
          {invoiceType === "3" && (
            <>
              <InputForm
                register={register}
                type="text"
                name="idCSC"
                label="TOKEN DO CSC"
                errorMsg={errors.idCsc?.message}
              />
              <InputForm
                register={register}
                type="text"
                name="codeCSC"
                label="CÓDIGO DO CSC"
                errorMsg={errors.codCsc?.message}
              />
            </>
          )}
          <InputHidden ref={submitRef} type="submit" value="Submit" />
        </Form>
      </Container>
    </>
  );
};

EditRPS.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitRef: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
};

EditRPS.defaultProps = {
  options: [],
};

export default EditRPS;
