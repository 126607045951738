import React, { lazy, useEffect, useRef, useState } from "react";
import { cpf } from "cpf-cnpj-validator";
import { useTranslation, Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { InvoiceActions } from "store/ducks/invoice";
import { EmitterActions } from "store/ducks/emitter";
import { EnterpriseActions } from "store/ducks/enterprise";
import { LayoutActions } from "store/ducks/layout";
import Button from "components/core/Button";
import Modal from "components/core/Modal";
import NavBar from "components/core/NavBar";
import { Span } from "components/core/Typography";
import WithSpinner, { isInProgress, isPending } from "components/core/WithSpinner";
import WithSuspense from "components/core/WithSuspense";
import EmitterEdit from "components/presentation/Emitter/Edit";
import useRequest from "hooks/useRequest";
import useEmitter from "hooks/useEmitter";
import { sanitizeCPF } from "helpers";
import { ROUTES, LINKS, authenticationTypeEnum } from "utils/constants";

const SecondaryActivities = lazy(() => import("containers/Emitter/Edit/SecondaryActivities"));
const ProLaboreContainer = lazy(() =>
  import("components/presentation/Emitter/Edit/ProLaboreConfig"),
);
const InvoiceTypes = lazy(() => import("containers/Emitter/Edit/InvoiceTypes"));

const EmitterEditWithSpinner = WithSpinner(EmitterEdit);
const SecondaryActivitiesWithSuspense = WithSuspense(SecondaryActivities);
const ProLaboreContainerWithSuspense = WithSuspense(ProLaboreContainer);
const InvoiceTypesWithSuspense = WithSuspense(InvoiceTypes);

const EmitterEditContainer = () => {
  const { id } = useParams();
  const history = useHistory();
  const submitRef = useRef(null);
  const dispatch = useDispatch();
  const emitterFixed = useEmitter();
  const { t: translate } = useTranslation();
  const [emitterToUpdate, setEmitterToUpdate] = useState();
  const [primaryActivities, setPrimaryActivities] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSecondaryActivities, setShowActivitiesModal] = useState(false);
  const [showInvoiceTypes, setShowInvoiceTypes] = useState(false);
  const [oldInvoiceTypes, setOldInvoiceTypes] = useState([]);
  const [invoiceTypesToParams, setInvoiceTypesToParams] = useState([]);
  const [cpfValidate, setCpfValidate] = useState("");
  const [commentsToUpdate, setCommentsToUpdate] = useState(null);
  const [cpfRejected, setCpfRejected] = useState(false);
  const [hasAuthTypeDefault, setHasAuthTypeDefault] = useState(true);
  const { emitterDetails, statusOfRequests } = useSelector(({ emitter }) => emitter);
  const {
    types,
    activities,
    status,
    subStatus,
    regimes,
    authTypes,
    activityGroups,
    sources,
    responsibleSale,
    partnersObvia,
    financialStatus,
    EnterpriseRequests,
  } = useSelector(({ enterprise }) => enterprise);
  const { multiSelectItems } = useSelector(({ layout }) => layout);
  const handleCpf = ({ target: { value } }) => {
    setCpfValidate(sanitizeCPF(value));
  };
  const [prolaboreVisible, setProlaboreVisible] = useState(false);

  const proLaboresForLatestCompetence = {
    amount: 0,
  };

  const countProLaboresForLatestCompetence = (currentEmitterDetails) => {
    const { proLabores = [] } = currentEmitterDetails ?? {};
    if (proLabores.length === 0) {
      return;
    }
    const maxTimestamp = Math.max(
      ...proLabores.map((item) => new Date(item.competenceDate).getTime()),
    );
    const latestProLabores = proLabores.filter(
      (item) => new Date(item.competenceDate).getTime() === maxTimestamp,
    );

    return latestProLabores.length;
  };

  proLaboresForLatestCompetence.amount = countProLaboresForLatestCompetence(emitterDetails);

  useEffect(() => {
    if (cpfValidate) {
      const value = cpf.isValid(cpfValidate);
      if (value === false) {
        setCpfRejected(true);
      } else {
        setCpfRejected(false);
      }
    }
  }, [cpf, cpfValidate]);

  useEffect(() => {
    const invoiceTypesService = [{ id: 1, name: "NFS-e" }];
    if (!hasAuthTypeDefault) {
      const newMultiSelectItems = { ...multiSelectItems, invoiceTypes: invoiceTypesService };
      dispatch(LayoutActions.changeMultiSelectItems(newMultiSelectItems));
    }
  }, [hasAuthTypeDefault]);

  useEffect(() => {
    if (emitterDetails?.id !== Number(id)) {
      dispatch(EmitterActions.fetchEmitterById(id));
    }
  }, [dispatch, emitterDetails?.id, id]);

  const dispatchUpdates = () => {
    dispatch(EmitterActions.editEmitter(id, emitterToUpdate));
    if (emitterDetails?.id === emitterFixed?.id) {
      const newInvoiceTypes = emitterToUpdate?.invoiceTypes?.map((item) => {
        return {
          idInvoiceType: item,
        };
      });
      const updateFixedEmitter = {
        id: emitterDetails?.id,
        enterpriseName: emitterToUpdate?.name,
        cnpj: emitterToUpdate?.cnpj,
        user: emitterFixed?.user,
        comments: emitterToUpdate?.comments,
        enterpriseInvoiceType: newInvoiceTypes,
      };
      dispatch(LayoutActions.pinEmitter(updateFixedEmitter));
    }
    setShowConfirm(false);
  };

  const handleInvoiceTypes = (idAuthType) => {
    setHasAuthTypeDefault(Number(idAuthType) !== authenticationTypeEnum.idUsernamePassword);
  };

  const handleSubmit = ({
    issueInvoicePermission,
    secondaryActivities,
    invoiceTypes,
    issAliquot,
    ...rest
  }) => {
    setShowConfirm(true);
    const payload = { ...rest };

    if (payload.enterpriseSubStatusId === 0) payload.enterpriseSubStatusId = null;

    if (
      commentsToUpdate !== undefined &&
      commentsToUpdate !== null &&
      commentsToUpdate?.trim() === ""
    )
      payload.comments = commentsToUpdate;

    payload.issueInvoicePermission = issueInvoicePermission === "true";
    payload.secondaryActivities = secondaryActivities ? JSON.parse(secondaryActivities) : [];
    payload.invoiceTypes = invoiceTypes ? JSON.parse(invoiceTypes) : invoiceTypesToParams;

    if (issAliquot === "") {
      payload.issAliquot = null;
    } else {
      payload.issAliquot = issAliquot?.toString().replace(",", ".");
    }
    setEmitterToUpdate(payload);
  };

  const dispatchConfirmation = (text, type, title) =>
    dispatch(
      LayoutActions.showConfirmationModal({
        content: <Span>{text}</Span>,
        type,
        title,
      }),
    );

  useEffect(() => {
    dispatch(InvoiceActions.getAllInvoicesTypes());
    dispatch(EnterpriseActions.getActivityGroups());
    dispatch(EnterpriseActions.getSources());
    dispatch(EnterpriseActions.getResponsibleSale());
    dispatch(EnterpriseActions.getPartnersObvia());
    dispatch(EnterpriseActions.getFinancialStatus());
  }, [dispatch]);

  useEffect(() => {
    const { state, city, enterpriseInvoiceType, isNationalMEI, idEnterpriseType } = emitterDetails;
    if (state && city && enterpriseInvoiceType.find((x) => x.invoiceType.name === "NFS-e")) {
      dispatch(
        EnterpriseActions.fetchEmitterResources({
          state,
          city,
          activity: "",
          mei: (isNationalMEI && idEnterpriseType == 1) || idEnterpriseType == 1,
          idEnterpriseType,
        }),
      );
    } else {
      dispatch(
        EnterpriseActions.fetchEmitterResources({
          state: "MG",
          city: "Belo Horizonte",
          activity: "",
        }),
      );
    }
  }, [dispatch, emitterDetails]);

  useRequest({
    request: statusOfRequests.EDIT_EMITTER,
    onResolved: () => {
      dispatchConfirmation(
        <Trans
          i18nKey="emitterEdited"
          values={{ name: emitterDetails.enterpriseName }}
          components={{ bold: <strong /> }}
        />,
        "success",
      );
      history.push(ROUTES.emitters.path);
      dispatch(EmitterActions.cleanState());
    },
  });

  useEffect(() => {
    const newActivities = activities?.map(({ name, id }) => ({
      id,
      name: `${id} - ${name}`,
    }));
    setPrimaryActivities(newActivities);
  }, [activities]);

  const requestsIsLoading = () =>
    isInProgress(EnterpriseRequests.FETCH_EMITTER_RESOURCES) ||
    isPending(statusOfRequests.EDIT_EMITTER);

  useEffect(() => {
    const avaliableInvoiceTypes = emitterDetails?.enterpriseInvoiceType?.map(({ invoiceType }) => ({
      ...invoiceType,
    }));
    const invoiceToParams = avaliableInvoiceTypes?.map(({ id }) => id);

    setInvoiceTypesToParams(invoiceToParams);
    setOldInvoiceTypes(avaliableInvoiceTypes);
  }, [emitterDetails?.enterpriseInvoiceType]);

  return (
    <>
      <NavBar title={translate("editEmitterInformations")}>
        <Button url={LINKS.emitterDetails(Number(id))} variant="outline">
          {translate("cancel")}
        </Button>
        <Button
          handleClick={() => submitRef.current.click()}
          disabled={requestsIsLoading() || cpfRejected}
        >
          {translate("save")}
        </Button>
      </NavBar>
      <EmitterEditWithSpinner
        isLoading={requestsIsLoading()}
        requestStatus={statusOfRequests.details}
        onSubmit={handleSubmit}
        onInvoiceTypesChange={handleInvoiceTypes}
        emitterDetails={emitterDetails ?? {}}
        proLaboresForLatestCompetence={proLaboresForLatestCompetence}
        submitRef={submitRef}
        types={types}
        activities={primaryActivities}
        statusList={status}
        subStatusList={subStatus}
        regimes={regimes}
        AuthenticationTypes={authTypes}
        setShowActivitiesModal={setShowActivitiesModal}
        setProlaboreVisible={setProlaboreVisible}
        setShowInvoiceTypes={setShowInvoiceTypes}
        emitterToUpdate={emitterToUpdate}
        cpfValidate={cpfValidate}
        handleCpf={handleCpf}
        cpfRejected={cpfRejected}
        setCommentsToUpdate={setCommentsToUpdate}
        ResponsiblesForSale={responsibleSale}
        Sources={sources}
        ActivityGroups={activityGroups}
        PartnersObvia={partnersObvia}
        FinancialStatus={financialStatus}
      />
      <SecondaryActivitiesWithSuspense
        loadComponent={showSecondaryActivities}
        showModal={showSecondaryActivities}
        setShowModal={setShowActivitiesModal}
        prevSelected={emitterDetails.secondaryActivities}
      />
      <ProLaboreContainerWithSuspense
        loadComponent={prolaboreVisible}
        prolaboreVisible={prolaboreVisible}
        setProlaboreVisible={setProlaboreVisible}
        emitterDetails={emitterDetails ?? {}}
        dispatchConfirmation={dispatchConfirmation}
      />
      <InvoiceTypesWithSuspense
        loadComponent={showInvoiceTypes}
        showModal={showInvoiceTypes}
        setShowModal={setShowInvoiceTypes}
        prevSelected={oldInvoiceTypes}
        hasTypesDefault={hasAuthTypeDefault}
        multiSelectItems={multiSelectItems}
      />
      <Modal
        showModal={showConfirm}
        onCloseModal={() => setShowConfirm(false)}
        onConfirmModal={dispatchUpdates}
        title={translate("confirmChanges")}
        width="49rem"
      >
        <Span>
          <Trans
            i18nKey="confirmUserChanges"
            values={{ name: emitterDetails?.enterpriseName }}
            components={{ bold: <strong /> }}
          />
        </Span>
      </Modal>
    </>
  );
};

export default EmitterEditContainer;
