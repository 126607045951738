export default {
  accessInformationSentByEmail:
    "* As instruções de acesso ao sistema serão enviadas para o e-mail acima.",
  add: "Adicionar",
  addInformations: "Adicione as informações",
  editInformations: "Edite as informações",
  additionalBilletPrice: "Acréscimo por Pagamento via Boleto",
  addNcm: "Adicionar NCM",
  addCoupons: "Adicionar Cupom",
  addCfop: "Adicionar CFOP",
  addCsosn: "Adicionar CSOSN",
  addProducts: "Adicionar Produto",
  addServices: "Adicionar Serviços",
  addUser: "Adicionar Usuário",
  allPlans: "Todos os planos",
  allCategory: "Todos as categorias",
  allStates: "Todos Estados",
  amount: "Quantidade",
  amountUsed: "Utilizações",
  amountInvoices: "Quant. notas",
  amountAuthorizedInvoices: "Notas Aprovadas",
  anualDiscount: "Valor de Desconto (Plano Anual)",
  attendance: "Atendimento",
  attentionAreYouSure: "Atenção, tem certeza disto ?",
  atLeastOne: "Pelo menos um campo, deve ser preenchido",
  AuthenticationType: "Formas de autenticação",
  batche: "Lote",
  batches: "Emissão em massa",
  xmlScreen: "Arquivos de clientes",
  batchesCreated:
    "O processamento das notas pode levar algum tempo. Ao entrar no detalhe do lote você conseguirá visualizar e acompanhar o status de todas as notas.",
  batchesCreatedError: "Não foi possível criar o Lote.",
  batchesList: "Lista de lotes",
  batchesListId: "<bold> Lote {{id}}</bold>",
  batchesListDescription: "Descrição breve de um lote criado.",
  batchesDetailsTitle: "Notas emitidas",
  batchesDetails: "<bold> Detalhes da Nota</bold>",
  batchDescription: "Descrição do lote",
  batchesSelected: "{{count}} Empresas selecionada",
  addBatches: "Criar Lote",
  billingRange: "Entre {{min}} e R$ {{max}}",
  both: "Ambos",
  byPeriod: "Por periodo",
  certificateSendSuccess: "Certificado Enviado com Sucesso",
  cost: "Valor",
  cfop: "CFOP",
  cfopList: "Lista de CFOP'S",
  cfopDescription: "Descrição do CFOP",
  changePlan: "Alterar Plano",
  cities: "Cidades",
  code: "Código",
  currentPlan: "Plano Atual",
  competencyDate: "Data de Competência",
  competencyDateEx: "Data de Competência (mm/aaaa)",
  confirmChanges: "Confirmar Alterações",
  confirmationUploadSpread:
    "Importar essa planilha irá substituir a anterior. Tem certeza que deseja continuar?",
  confirmDelete: "Confirmar exclusão",
  invoiceNumber: "Número da nota",
  issueInvoices: "Emitindo notas",
  issueInvoicesReport:
    "Estamos emitindo as notas. Para minimizar problemas, não saia ou recarregue esta página durante o processo.",
  issueInvoicePermission: "Permissão para gerar nota.",
  importSpread: "Importar Planilha",
  choosePlans: "Escolher plano(s)",
  chooseCategory: "Escolher categoria",
  chooseCities: "Escolher cidades",
  chooseState: "Escolher estado",
  chooseStates: "Escolher estados",
  confirmUserChanges: "Você confirma a alteração nos dados de <bold>{{name}}</bold>?",
  confirmProfileChanges: "Você confirma as alterações nos dados de seu perfil?",
  coveragePlans: "Cobertura de planos",
  coverageEnterprises: "Cobertura de empresas",
  coverageStates: "Cobertura de estados",
  coverageCities: "Cobertura de Cidades",
  crc: "CRC",
  csosn: "CSOSN",
  csosnList: "Lista de CSOSN'S",
  csosnDescription: "Descrição do CSOSN",
  culturalPromoter: "Incentivador cultural",
  csllAliquot: "CSLL",
  cofinsAliquot: "COFINS",
  coupon: "Cupom",
  coupons: "Cupons",
  couponsCode: "Código do cupom",
  couponsCreated: "Cupom feito com sucesso",
  couponsEdited: "Cupom editado com sucesso",
  couponsSelected: "Cupons Selecionados",
  couponsUsed: "Cupons utilizados",
  city: "Cidade",
  valuesConfig: "Configurar Valores",
  invoiceType: "Tipo da nota fiscal",
  discount: "Desconto",
  dateIssue: "Data de emissão",
  deleteCfop: "Excluir CFOP",
  definePriceForAll: "Definir Valores para todos",
  definePriceForOne: "Escolher valores individualmente",
  deleteCfopConfirm: "Você tem certeza que deseja deletar os CFOP's selecionados?",
  deleteCoupons: "Excluir Cupons",
  deleteCouponsConfirm: "Você tem certeza que deseja deletar os Cupons selecionados?",
  deleteCsosn: "Excluir CSOSN",
  deleteCsosnConfirm: "Você tem certeza que deseja deletar os CSOSN's selecionados?",
  deleteDocument: "Excluir Documento",
  deleteDocumentConfirm: "Você tem certeza que deseja deletar o documento?",
  deleteMessageConfirm:
    "A mensagem não poderá mais ser vista por você ou por <bold>{{name}}</bold>",
  deleteNcm: "Excluir NCM",
  deleteNcmConfirm: "Você tem certeza que deseja deletar os NCM's selecionados?",
  deleteProducts: "Excluir Produtos",
  deleteProductsConfirm: "Você tem certeza que deseja deletar os produtos selecionados?",
  deleteServices: "Excluir Serviços",
  deleteEnterprise: "Excluir Empresas",
  deleteEnterpriseConfirm: "Você tem certeza que deseja deletar as empresas selecionadas?",
  disableCoupons: "Desativar Cupons",
  disableCouponsConfirm: "Você tem certeza que deseja desativar os Cupons selecionados?",
  discountReceived: "Desconto recebido",
  service: "Serviço",
  deleteServicesConfirm: "Você tem certeza que deseja deletar os serviços selecionados?",
  digitalAuthorization: "Autorização Digital",
  downloadDocuments: "Download de Documentos",
  edit: "Editar",
  editCoupons: "Editar cupons",
  editDetailsEmitter: "Editar Detalhes do Cliente",
  editEmitterInformations: "Editar Informações de Emissor",
  editMyProfile: "Editar meu Perfil",
  editPlace: "Editar Local",
  editRPS: "Editar RPS",
  editRPSInformation: "Editar Informações de RPS",
  editTaxes: "Editar Taxas",
  editUser: "Editar Usuário",
  editNcmGlobals: "Editar NCM Global",
  editSMU: "Editar informações sobre SMU",
  editPlan: "Editar Plano",
  editProduct: "Editar produto",
  email: "E-mail",
  emitterEdited: "As informações de <bold>{{name}}</bold> foram alteradas com sucesso.",
  emitterName: "Nome do Emissor",
  emitterNameOrStoreClient: "Nome do Emissor ou Cliente Loja Online",
  emitterNotFound: "Emissor não encontrado.",
  emitterSelected: "{{count}} Emissor selecionado",
  emitterSelected_plural: "{{count}} Emissores selecionados",
  emitterStatusChanged: "O status do emissor foi alterado para <bold>{{status}}</bold>",
  emitterStatusChanged_plural:
    "<bold>{{count}}</bold> emissores com o status alterado para <bold>{{status}}</bold>",
  emitterStatusIsAlready: "O emissor já está com o status <bold>{{status}}</bold>",
  emitterStatusIsAlready_plural:
    "<bold>{{count}}</bold> deles já estão com o status <bold>{{status}}</bold>",
  emptyList: "Nenhuma informação foi encontrada.",
  endDate: "Data de término",
  enterCategoryName: "Digite o nome da categoria",
  enterpriseTypes: "Tipos de Empresas",
  enterprises: "Empresas",
  errorBoundary: "Algo deu errado, por favor tente acessar a página novamente em instantes.",
  exit: "Sair",
  filterCfop: "Filtrar CFOP's",
  filterClients: "Filtrar Tomadores",
  filterCoupons: "Filtrar Cupons",
  filterDocument: "Filtrar Documento",
  filterInvoices: "Filtrar NFe",
  filterNcmGlobals: "Filtar NCM Globais",
  filterUsers: "Filtrar Usuários",
  firstPayment: "Primeiro Pagamento",
  firstPaymentDate: "Data do Primeiro Pagamento",
  nextPaymentDate: "Data do Próximo Pagamento",
  forgotPassword: "Esqueceu a senha?",
  generalTaxes: "Taxas Gerais",
  hi: "Olá",
  information: "Informações",
  installmentValue: "Valor da Parcela",
  curentInstallmentValue: "Valor Atual da Parcela",
  paymentMethod: "Forma de pagamento",
  invoiceDetails: "Detalhes da Nota",
  invoices: "Notas Fiscais",
  invoiceIssued: "Notas emitidas",
  invoicesSelected: "{{count}} Nota selecionada",
  invoicesSelected_plural: "{{count}} Notas selecionadas",
  invoicesState: "Status da Nota",
  invoicesType: "Tipo da Nota",
  invoiceValue: "Valor da Nota",
  issue: "Emitir",
  issueInvoicesRejected: "Notas com erros:",
  issueWithCertificate: "Emite com Certificado digital",
  issueWithoutCertificate: "Emite sem certificado digital",
  itemsNotFound: "Nenhum item encontrado.",
  irAliquot: "IRRJ",
  inssAliquot: "INSS",
  justification: "Justificativa",
  lastInvoice: "Data da última nota",
  lastInvoicePrice: "Valor da última nota",
  locality: "Localidade",
  localServiceProvision: "Local de Prestação de Serviço",
  manageInvoices: "Administrar NFes",
  manageProducts: "Administrar Produtos",
  municipalServices: "Serviços Municipais",
  multipleInvoicesEmptyError: "Para emitir múltiplas notas é necessário criar pelo menos um lote.",
  municipalServiceCode: "Código de serviço municipal",
  municipalServiceName: "Nome do Serviço",
  myProfile: "Meu Perfil",
  ncm: "NCM",
  ncmCategory: "Categoria NCM",
  ncmCode: "Código do NCM",
  ncmCodeOrName: "Nome ou código NCM",
  ncmList: "Lista de NCM'S",
  ncmCategoryCreated: "Categoria de NCM's feito com sucesso",
  ncmDescription: "Descrição do NCM",
  ncmGlobals: "NCM Globais",
  ncmGlobalsCreated: "NCM Global feito com sucesso",
  ncmGlobalsEdited: "NCM Global editado com sucesso",
  newBatch: "Novo Lote",
  newCertificate: "Novo Certificado",
  newCoupons: "Adicionar cupom",
  newNcmCategory: "Adicionar categoria de NCM's",
  newNcmGlobals: "Adicionar um NCM",
  ncmGlobalsSelected: "NCMs Globais Selecionados {{count}}",
  newService: "Novo Atendimento",
  newUser: "Novo Usuário",
  notesIssued: "Notas emitidas",
  notifications: "Notificações",
  numberOfUses: "Total de cupons da campanha",
  oneUserSelected: "1 usuário selecionado",
  password: "Senha",
  passwordCertificate: "Senha do Certificado",
  payrollPrice: "Valor por Folha de Pagamento",
  plan: "Plano",
  plans: "Planos",
  plansContemplated: "Planos contemplados",
  presumed: "Lucro Presumido",
  products: "Produtos",
  productAdd: "Produto Adicionado",
  planStartDate: "Data de início do plano",
  planEndDate: "Data de término do plano",
  productEdited: "Produto Editado",
  planEdited: "Plano Alterado",
  productsList: "Lista de Produtos",
  productCode: "Código do produto",
  productDetails: "Detalhes do produto",
  productDescription: "Descrição do Produtos",
  productRegister: "Adicionar Produto",
  profileEdited: "As informações do seu perfil foram alteradas com sucesso.",
  profileType: "Tipo do Perfil",
  programming: "Programação",
  provisionalServiceReceipt: "Recibo Provisório de Serviços",
  pisAliquot: "PIS",
  reasonStatus: "Motivo do Status",
  recipient: "Destinatário",
  recipientName: "Nome do Destinatário",
  rememberMe: "Lembrar-se de mim",
  requiredField: "Campo Obrigatório",
  rpsSendSuccess: "RPS Atualizado com Sucesso",
  rpsSerie: "Recibo Provisório de Serviços (RPS)",
  searchConversation: "Procure um atendimento",
  searchCities: "Digite o nome da cidade",
  searchStates: "Digite o UF do estado",
  secretPhrase: "Frase Secreta",
  seeEmitter: "Visualizar Emissor",
  seeProfile: "ver perfil",
  selectCities: "Selecionar Cidades",
  selectOrStartNewService: "Inicie ou selecione um atendimento.",
  selectedCities: "Cidades selecionadas",
  selectSpecificCities: "Selecionar cidades específicas",
  selectedStates: "Estados selecionados",
  sendAttachment: "Enviar Anexo",
  sendDocument: "Enviar Documento",
  series: "Série",
  services: "Serviços",
  serviceEdited: "Serviço Editado",
  serviceListItem: "Item da lista de serviço",
  serviceLocation: "Local de Retenção",
  serviceProvider: "Serviço Prestado",
  serviceDescription: "Descrição do serviço",
  showPassword: "Mostrar a senha",
  simple: "Simples",
  smu: "SMU",
  specialTaxRegime: "Regime especial de tributação",
  startDate: "Data de início",
  subscriptionPlans: "Cobertura de planos",
  takers: "Tomadores",
  type: "Tipo",
  typeYourMessageHere: "Digite sua mensagem aqui...",
  unifiedMunicipalServices: "SMU (Serviço Municipal Unificado)",
  unifiedMunicipalService: "Serviço Municipal Unificado",
  aliquots: "Alíquotas",
  unitMeasure: "Unidade de Medida",
  uploadDocuments: "Upload de Documentos",
  user: "Usuário",
  userCreated: "<bold>{{name}}</bold> foi adicionado a lista de usuários com sucesso.",
  userEdited: "Usuário foi editado com sucesso.",
  users: "Usuários",
  usersSelected: "{{count}} usuário selecionado",
  usersSelected_plural: "{{count}} usuários selecionados",
  uf: "Estado",
  status: "Situação",
  restart: "Reemitir",
  reprocess: "Re-processar",
  update: "Atualizações",
  cpfCnpj: "CPF/CNPJ",
  cnpj: "CNPJ",
  statusType: "filtrar notas por status",
  allVeryWell: "Tudo certo!",
  reissueSuccess: "A tentativa de re-processamento da nota foi enviada com sucesso.",
  canceledSuccess: "A tentativa de cancelar a nota foi enviada com sucesso.",
  export: "Exportar",
  nfe: "NFe",
  pdf: "Exportar como <bold>PDF<bold>",
  xml: "Exportar como <bold>XML<>",
  cancel: "Cancelar",
  Subscriptions: "Assinaturas",
  "Next Billings": "Próximos Faturamentos",
  "Next Charges": "Próximas Cobranças",
  "Payment Gateways": "Meios de Pagamento",
  "Edit Payment Gateway": "Editar Meio de Pagamento",
  Name: "Nome",
  Active: "Ativo",
  Default: "Padrão",
  Yes: "Sim",
  No: "Não",
  "Payment Gateway Edited": "Meio de Pagamento Editado",
  Value: "Valor",
  "Plan Type": "Tipo de Plano",
  "Plan Value": "Valor do Plano",
  "Apply to Plans": "Aplicar ao(s) plano(s)",
  Plan: "Plano",
  Plans: "Planos",
  mensal: "Mensal",
  anual: "Anual",
  bienal: "Bienal",
  Charge: "Cobrança",
  "Emitter Type": "Tipo de Emissor",
  Financial: "Financeiro",
  Reference: "Referência",
  "Due Date": "Vencimento",
  "Subscription Due Date": "Vencimento da Assinatura",
  "Due Day": "Dia do Vencimento Fatura",
  "Last Billing Period": "Último Faturamento",
  "Next Billing Date": "Próx. Fat. Automático",
  "Next Payment Date": "Próximo Pagamento",
  "Subscription Monthly Items": "Items da Assinatura Mensal",
  "Subscription Yearly Items": "Items da Assinatura Anual",
  "Subscription Biennial Items": "Items da Assinatura Bienal",
  Quantity: "Quantidade",
  Recurrent: "Recorrente",
  "At/Billing from": "Em/A partir de",
  Actions: "Ações",
  processingRequest: "Processando requisição...",
  Reports: "Relatórios",
  ReportsSendFiles: "Relatório de Envio de Arquivos",
  "Global Settings": "Configurações Globais",
  "Global Installments Settings": "Parcelamento - Configurações Globais",
  "Enterprise Type": "Tipo de Empresa",
  "Allow Installments": "Permitir Parcelamento",
  "Allow Installments First Subscription": "Permitir Parcelamento na Assinatura",
  "Allowed Plans": "Planos Permitidos",
  Monthly: "Mensal",
  Annual: "Anual",
  "Maximum Installments Number": "Nº Máximo de Parcelas",
  "Interest Type": "Tipo de Juros",
  "Interest Value": "Percentual de Juros",
  "Maximum Installments": "Nº Máximo de Parcelas",
  "Parcel Interest": "Percentual de Juros",
  Version: "Versão",
  "Global Setting added successfully": "Configuração Global adicionada com sucesso",
  "Global Setting edited successfully": "Configuração Global editada com sucesso",
  Interest: "Juros",
  Installments: "Parcelamento",
  "Installments Settings": "Configurações de Parcelamento",
  "Inherit Settings": "Herdar Configurações",
  "Update emitters settings": "Atualizar configurações dos emissores",
  "Edit Settings": "Editar Configurações",
  "Discount Type": "Tipo de Desconto",
  "Discount Value": "Valor do Desconto",
  "Fixed Value": "Valor Fixo",
  Percent: "Percentual",
  "National MEI": "MEI Nacional",
  "Discount Description": "Descrição do Desconto",
  Invoices: "Faturas",
  Pending: "Pendente",
  pending: "Pendente",
  Canceled: "Cancelado",
  canceled: "Cancelado",
  Expired: "Expirado",
  expired: "Expirado",
  Overdue: "Vencido",
  overdue: "Vencido",
  Paid: "Pago",
  paid: "Pago",
  externally_paid: "Pago Externamente",
  Externally_paid: "Pago Externamente",
  Refunded: "Reembolsado",
  refunded: "Reembolsado",
  processingPayment: "Processando Pagamento",
  ProcessingPayment: "Processando Pagamento",
  "Payment Link": "Link para Pagamento",
  "Copy Link": "Copiar Link",
  "Payment Link Copied": "Link para Pagamento Copiado",
  "Open in new tab": "Abrir em nova aba",
  Refresh: "Atualizar",
  "Download to excel": "Download em excel",
  "Invoice Type": "Tipo de Fatura",
  typeDescription: "Digite a descrição",
  billingDays: "Dias de Faturamento",
  "Due Date is required": "Data de Vencimento é obrigatória",
  "Due Date cannot be in the past": "Vencimento não pode ser no passado",
  dateInFuture: "Data deve ser no futuro. Mínimo: {{date}}",
  removed: "Dados removidos com sucesso",
  removeError: "Erro ao remover os dados.<br/><br/><bold>{{errorMsg}}</bold>",
  invoiceCanceled: "Fatura cancelada com sucesso",
  invoiceCanceledError: "Erro ao cancelar a fatura.<br/><br/><bold>{{errorMsg}}</bold>",
  subscriptionBilled: "Assinatura faturada com sucesso",
  subscriptionBilledError: "Erro ao faturar a assinatura.<br/><br/><bold>{{errorMsg}}</bold>",
  saved: "Dados salvos com sucesso",
  saveError: "Erro ao salvar os dados.<br/><br/>{{errorMsg}}",
  couponError: "Cupom inválido.<br/><br/>{{errorMsg}}",
  dataExported:
    "Sua solicitação foi enviada com sucesso. Em breve você receberá um e-mail com o link para download dos dados.",
  dataExportedError: "Erro ao exportar os dados.<br/><br/>{{errorMsg}}",
  "Subscription Invoices": "Faturas da Assinatura",
  "Payment Date": "Data de Pagamento",
  "Payment Value": "Valor do Pagamento",
  Bill: "Faturar",
  "Allow Billing": "Permitir Faturamento",
  "Export selected": "Exportar selecionados",
  "Clear selection": "Limpar seleção",
  "Export all": "Exportar todos",
  "Installments Number": "Número de Parcelas",
  subscriptionCreated: "Assinatura criada com sucesso",
  subscriptionCreateError: "Erro ao criar a assinatura.<br/><br/>{{errorMsg}}",
  sendedToProtheus: "Fatura enviada para o Protheus com sucesso",
  sendToProtheusError: "Erro ao enviar a fatura para o Protheus.<br/><br/>{{errorMsg}}",
  charged: "Cobrança efetuada com sucesso<br/><br/>{{info}}",
  chargeError: "Erro ao efetuar a cobrança.<br/><br/><bold>{{errorMsg}}</bold>",
  User: "Usuário",
  Date: "Data",
  "Charged by": "Cobrado por",
  "Charged at": "Cobrado em",
  "Possible actions": "Ações possíveis",
  Brand: "Bandeira",
  "Final card digits": "Final do cartão",
  "Irreversible status for brands": "Situação irreversível para as bandeiras",
  Bank: "Banco",
  creditCard: "Cartão de Crédito",
  bankSlip: "Boleto",
  tasksManagement: "Controle de Tarefas",
  tasksFilter: "Filtrar Tarefas",
};
