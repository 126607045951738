import React, { useEffect, useRef, useState } from "react";
import api from "services/api";
import toast from "react-hot-toast";
import Modal from "components/core/Modal";
import Button from "components/core/Button";
import InputForm from "components/core/InputForm";
import useReload from "hooks/useReload";
import ModalCreateService from "../ModalCreateService";
import ModalCreateEvent from "../ModalCreateEvent";
import { Form } from "components/core/Form";
import { Flex } from "components/core/Grid";
import { Spinner } from "../WithSpinner/styled";
import { ModalActions } from "components/core/Modal/styled";
import { SelectWithDot } from "components/core/SelectForm";
import { COUPONS_COLORS_DICTIONARY } from "utils/constants";
import { FiPlus } from "react-icons/fi";

const ModalCreateWebHook = ({ showModal, onCloseModal }) => {
  const formRef = useRef(null);
  const { reloadPage } = useReload();
  const [loading, setLoading] = useState(false);
  const [openModalService, setOpenModalService] = useState(false);
  const [openModalEvent, setOpenModalEvent] = useState(false);
  const [services, setServices] = useState([]);
  const [events, setEvents] = useState([]);
  const getServices = async () => {
    try {
      const response = await api.get("/services");
      setServices(response.data);
    } catch (error) {
      console.error("Erro ao buscar serviços:", error);
    }
  }
  const getEvents = async () => {
    try {
      const response = await api.get("/events");
      setEvents(response.data);
    } catch (error) {
      console.error("Erro ao buscar eventos:", error);
    }
  }
  useEffect(() => {
    getServices();
  }, [openModalService]);
  useEffect(() => {
    getEvents();
  }, [openModalEvent]);
  const validate = (data) => {
    if (data.serviceId == 0) {
      toast.error("O campo servico é obrigatório!", { position: "top-right" });
      return false;
    }
    if (data.eventId == 0) {
      toast.error("O campo evento é obrigatório!", { position: "top-right" });
      return false;
    }
    if (data.status == -1) {
      toast.error("O campo status é obrigatório!", { position: "top-right" });
      return false;
    };
    return true;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    if (!validate(data)) {
      setLoading(false);
      return;
    }
    try {
      const response = await api.post("/webhooks", data);
      toast.success("Webhook criado com sucesso!", { position: "top-right" });
      onCloseModal();
      formRef.current.reset();
      reloadPage();
    } catch (error) {
      toast.error(error.errors.data.message, { position: "top-right" });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Modal
        title="Criar Webhook"
        confirmLabel="Criar"
        onCloseModal={() => onCloseModal()}
        showModal={showModal}
        textAlign="text-left"
        showActions={false}
      >
        <Flex verticalAlign="center">
          <Form ref={formRef} onSubmit={handleSubmit}>
            <InputForm
              type="text"
              name="name"
              label="Nome"
              placeholder="Digite um nome..."
              required
            />
            <InputForm
              type="text"
              name="description"
              label="Descrição"
              placeholder="Digite uma descrição..."
              required
            />
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1px', alignItems: 'end', width: '100%' }}>
              <button onClick={setOpenModalService} type="button" style={{ border: '.1px solid gray', borderRadius: '3px', cursor: 'pointer', paddingTop: '2px', paddingBottom: '2px', paddingRight: '3px', paddingLeft: '3px' }}>
                <FiPlus size={14} style={{ color: 'var(--color-primary)' }}/>
              </button>
              <SelectWithDot
                id="serviceId"
                name="serviceId"
                label="Serviço"
                mb="3.2rem"
                options={[
                  { value: 0, text: "Selecione um serviço" },
                  ...services?.map((service, name) => {
                    return { 
                      value: service.id,
                      text: service.name,
                      color: COUPONS_COLORS_DICTIONARY[name]
                    };
                  })
                ]}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1px', alignItems: 'end', width: '100%' }}>
              <button onClick={setOpenModalEvent} type="button" style={{ border: '.1px solid gray', borderRadius: '3px', cursor: 'pointer', paddingTop: '2px', paddingBottom: '2px', paddingRight: '3px', paddingLeft: '3px' }}>
                <FiPlus size={14} style={{ color: 'var(--color-primary)' }}/>
              </button>
              <SelectWithDot
                id="eventId"
                name="eventId"
                label="Evento"
                mb="3.2rem"
                options={[
                  { value: 0, text: "Selecione um evento" },
                  ...events?.map((event, name) => {
                    return {
                      value: event.id,
                      text: event.name,
                      color: COUPONS_COLORS_DICTIONARY[name]
                    }
                  })
                ]}
                required
              />
            </div>
            <InputForm type="text" name="url" label="Link" placeholder="Digite um link..." required />
            <InputForm
              type="text"
              name="model"
              label="Model"
              placeholder="Digite o nome da model..."
              required
            />
            <SelectWithDot
              id="status"
              name="status"
              label="Status"
              mb="3.2rem"
              options={[
                { value: -1, text: "Selecione um Status" },
                { value: 1, text: "Ativo" },
                { value: 0, text: "Inativo" },
              ]}
            />
            <ModalActions>
              <Button variant="outline" handleClick={onCloseModal}>
                Cancelar
              </Button>
              <Button type="submit" disabled={loading}>
                {
                  loading ? (
                    <Spinner margin={'auto'} width={'2rem'} height={'2rem'}/>
                  ) : "Criar"
                }
              </Button>
            </ModalActions>
          </Form>
        </Flex>
      </Modal>
      <ModalCreateService open={openModalService} onClose={() => setOpenModalService(false)} />
      <ModalCreateEvent open={openModalEvent} onClose={() => setOpenModalEvent(false)} />
    </>
  );
};

export default ModalCreateWebHook;