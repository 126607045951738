import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import WithEmptyFeedback from "components/core/WithEmptyFeedback";
import { Table, TableHeader, TableBody, TableItem, RenderHeaders } from "components/core/Table";
import Pagination from "components/core/Pagination";
import StatusDot, { StatusTypes } from "components/core/StatusDot";
import { urls } from "utils/constants";
import { Menu, MenuItem } from "@mui/material";
import { useDispatch } from "react-redux";
import { TaskActions } from "store/ducks/tasks";
import { STATUS_OPTIONS } from "helpers";

const getStatusDotType = (statusName) => {
  switch (statusName) {
    case "Concluído":
      return StatusTypes.SUCCESS;
    case "Em Andamento":
      return StatusTypes.PROCESSING;
    case "Pendente":
      return StatusTypes.WARNING;
    default:
      return StatusTypes.INFO;
  }
};

const List = ({ tasks, setPage, total, totalPages, page, perPage, setOrderBy, orderBy, order }) => {
  const {
    LINKS: { paymentGatewaysDetails },
  } = urls;

  const safeTasks = Array.isArray(tasks) ? tasks : [];

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentTask, setCurrentTask] = useState(null);
  const [taskStatus, setTaskStatus] = useState(safeTasks);

  useEffect(() => {
    setTaskStatus(safeTasks);
  }, [safeTasks]);

  const handleOpenMenu = (event, task) => {
    setAnchorEl(event.currentTarget);
    setCurrentTask(task);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setCurrentTask(null);
  };

  const handleStatusSelect = (taskId, newStatus) => {
    dispatch(TaskActions.editTask(taskId, { status: newStatus }));

    setTaskStatus((prevTasks) =>
      prevTasks.map((task) =>
        task.id === taskId
          ? { ...task, taskStatus: { id: newStatus.id, name: newStatus.name } }
          : task,
      ),
    );
    handleCloseMenu();
  };

  const headers = [
    { slug: "id", title: "ID", textAlign: "center" },
    { slug: "description", title: "Descrição", textAlign: "left" },
    { slug: "enterpriseName", title: "Razão Social", textAlign: "left" },
    { slug: "cnpj", title: "CNPJ", textAlign: "left" },
    { slug: "status", title: "Status", textAlign: "left" },
    { slug: "reviewer", title: "Revisor", textAlign: "left" },
    { slug: "reviewDate", title: "Data de Revisão", textAlign: "left" },
  ];

  return (
    <>
      <Table>
        <TableHeader>
          <RenderHeaders
            headers={headers}
            setOrderBy={setOrderBy}
            orderBy={orderBy}
            order={order}
          />
        </TableHeader>
        <TableBody>
          {taskStatus.map((task) => {
            const { id, description, reviewDate, taskStatus, reviewer, enterprise } = task;

            return (
              <tr key={id}>
                <TableItem textAlign="center">
                  <Link to={paymentGatewaysDetails(id)}>{id}</Link>
                </TableItem>
                <TableItem>{description}</TableItem>
                <TableItem>{enterprise?.enterprise_name ?? "—"}</TableItem>
                <TableItem>{enterprise?.cnpj ?? "—"}</TableItem>
                <TableItem>
                  <div
                    style={{ cursor: "pointer", display: "inline-flex", alignItems: "center" }}
                    onClick={(e) => handleOpenMenu(e, task)}
                  >
                    <StatusDot type={getStatusDotType(taskStatus?.name)} />
                    <span style={{ marginLeft: "5px" }}>{taskStatus?.name}</span>
                  </div>
                </TableItem>
                <TableItem>{reviewer?.name ?? "—"}</TableItem>
                <TableItem>
                  {reviewDate ? new Date(reviewDate).toLocaleDateString("pt-BR") : "—"}
                </TableItem>
              </tr>
            );
          })}
        </TableBody>
      </Table>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        disableAutoFocusItem
        disableEnforceFocus
      >
        {STATUS_OPTIONS.map((status) => (
          <MenuItem key={status.id} onClick={() => handleStatusSelect(currentTask.id, status)}>
            {status.name}
          </MenuItem>
        ))}
      </Menu>

      <Pagination
        perPage={perPage}
        totalItems={total}
        actualPage={page}
        lastPage={totalPages}
        setPage={setPage}
      />
    </>
  );
};

List.propTypes = {
  tasks: PropTypes.array,
  setPage: PropTypes.func.isRequired,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  setOrderBy: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

List.defaultProps = {
  tasks: [],
  total: 0,
  totalPages: 0,
  page: 1,
  perPage: 10,
};

export default WithEmptyFeedback(List);
