import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ReloadProvider } from "hooks/useReload";
import { Toaster } from "react-hot-toast";
import Routes from "routes";
import TagManager from "react-gtm-module";
import GlobalStyle from "globalStyle";

const tagManagerArgs = {
  gtmId: "GTM-MDQZCBB",
};

TagManager.initialize(tagManagerArgs);

const App = () => {
  useEffect(() => {
    const askUserPermission = async () => {
      return Notification.requestPermission();
    };
    askUserPermission();
  }, []);

  return (
    <ReloadProvider>
      <Router>
        <GlobalStyle />
        <Routes />
        <Toaster/>
      </Router>
    </ReloadProvider>
  );
};

export default App;
