import { createActions, createReducer } from "reduxsauce";
import { REQUEST_NOT_STARTED, REQUEST_PENDING, REQUEST_RESOLVED } from "utils/constants/request";

export const { Types: TaskTypes, Creators: TaskActions } = createActions({
  editTask: ["id", "payload"],
  editTaskSuccess: ["payload"],
  fetchTasks: ["params"],
  fetchTasksSuccess: ["payload"],
  cleanTasksRequests: null,
});

const INITIAL_STATE = {
  tasks: {
    items: [],
  },
  tasksDetails: null,
  created: false,
  task: {},
  TasksRequestsStatus: {
    [TaskTypes.EDIT_TASK]: REQUEST_NOT_STARTED,
    [TaskTypes.FETCH_TASKS]: REQUEST_NOT_STARTED,
  },
};

const editTask = (state) => ({
  ...state,
  TasksRequestsStatus: {
    ...state.TasksRequestsStatus,
    [TaskTypes.EDIT_TASK]: REQUEST_PENDING,
  },
});

const editTaskSuccess = (state, { payload }) => ({
  ...state,
  tasksDetails: payload,
  TasksRequestsStatus: {
    ...state.TasksRequestsStatus,
    [TaskTypes.EDIT_TASK]: REQUEST_RESOLVED,
  },
});
const fetchTasks = (state) => ({
  ...state,
  TasksRequestsStatus: {
    ...state.TasksRequestsStatus,
    [TaskTypes.FETCH_TASKS]: REQUEST_PENDING,
  },
});

const fetchTasksSuccess = (state, { payload }) => ({
  ...state,
  tasks: payload,
  TasksRequestsStatus: {
    ...state.TasksRequestsStatus,
    [TaskTypes.FETCH_TASKS]: REQUEST_RESOLVED,
  },
});

const cleanTasksRequests = (state) => ({
  ...state,
  TasksRequestsStatus: {
    ...state.TasksRequestsStatus,
    [TaskTypes.EDIT_TASK]: REQUEST_NOT_STARTED,
    [TaskTypes.FETCH_TASKS]: REQUEST_NOT_STARTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [TaskTypes.EDIT_TASK]: editTask,
  [TaskTypes.EDIT_TASK_SUCCESS]: editTaskSuccess,
  [TaskTypes.FETCH_TASKS]: fetchTasks,
  [TaskTypes.FETCH_TASKS_SUCCESS]: fetchTasksSuccess,
  [TaskTypes.CLEAN_TASKS_REQUESTS]: cleanTasksRequests,
});
