import React from "react";
import Modal from "components/core/Modal";

const ModalChangeStatus = ({ open, onClose, handleChange }) => {
  return (
    <Modal
      title={"Alterar status"}
      confirmLabel={"Sim"}
      cancelLabel={"Não"}
      showModal={open}
      textAlign={"text-center"}
      showActions={true}
      onCloseModal={() => onClose()}
      onConfirmModal={() => handleChange(true)}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', textAlign: 'center', fontSize: '16px', padding: '10px' }}>
        <p>Deseja realmente alterar o status deste webhook?</p>
        <strong>Ao clicar sim, o status será alterado!</strong>
      </div>
    </Modal>
  )
}

export default ModalChangeStatus;