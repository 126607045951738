import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { EmitterActions } from "store/ducks/emitter";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import WithSpinner, { isInProgress } from "components/core/WithSpinner";
import Presentation from "components/presentation/DigitalAuthorization";
import SuccessScreen from "components/core/SuccessScreen";
import { useRequest } from "hooks";

const PresentationWithSpinner = WithSpinner(Presentation);

const DigitalAuthorization = () => {
  const submitRef = useRef();
  const dispatch = useDispatch();
  const { emitterId } = useParams();
  const { t: translate } = useTranslation();
  const [sendSuccess, setSendSuccess] = useState();
  const {
    emitterAuthTypes: { authType, certificate: certificateRequired },
    statusOfRequests,
  } = useSelector(({ emitter }) => emitter);

  useEffect(() => {
    dispatch(EmitterActions.fetchEmitterAuthTypes(emitterId));
  }, [dispatch, emitterId]);

  const handleSubmit = ({ certificate, ...values }) => {
    const formData = new FormData();

    if (certificateRequired) {
      formData.append("certificate", certificate[0]);
    }

    Object.keys(values).map((key) => formData.append(key, values[key]));

    dispatch(EmitterActions.editCertificate(emitterId, formData));
  };

  useRequest({
    request: statusOfRequests.EDIT_CERTIFICATE,
    onResolved: () => setSendSuccess(true),
  });

  return (
    <>
      <NavBar title={translate("digitalAuthorization")}>
        <Button handleClick={() => submitRef.current.click()}>
          <span>{translate("save")}</span>
        </Button>
      </NavBar>
      {sendSuccess ? (
        <SuccessScreen i18nKey="certificateSendSuccess" />
      ) : (
        <PresentationWithSpinner
          isLoading={isInProgress(statusOfRequests.FETCH_EMITTER_AUTH_TYPES)}
          requestStatus={statusOfRequests.EDIT_CERTIFICATE}
          authType={authType}
          certificate={certificateRequired}
          onSubmit={handleSubmit}
          submitRef={submitRef}
        />
      )}
    </>
  );
};

export default DigitalAuthorization;
