import React, { useEffect, useState } from "react";
import { Columns, Flex, RowFlex, FlexColumn } from "components/core/Grid";
import { Subtitle, Span } from "components/core/Typography";
import { formatNumberToReal } from "helpers/format";
import { Spinner } from "components/core/WithSpinner/styled";
import { InputGroup, InputGroupFlex } from "components/core/Form";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Button from "components/core/Button";
import CardInfo from "components/core/CardInfo";
import Feedback from "components/core/Feedback";
import RadioButton from "components/core/RadioButton";
import Label from "components/core/Label";
import Checkbox from "components/core/Checkbox";

const Payment = ({
  paymentData,
  copied,
  feedback,
  formatDate,
  handleClipboard,
  handleDownload,
  handleNext,
  hasItemBillet,
  invoice,
  isDownloaded,
  isDownloading,
  isMobile,
  paymentMethod,
  scriptLoaded,
  selectedPlan,
  setPaymentMethod,
  setSelectedPlan,
  setShowBilletForm,
  setShowPixForm,
  showBilletForm,
  showPixForm,
}) => {
  const { enterprise, planValues, subscription, paymentGateway } = invoice;
  const invoicePayment = useSelector((state) => state.invoicePayment);
  const [totalTax, setTotalTax] = useState(invoice?.totalTax);

  useEffect(() => {
    if (paymentGateway?.code !== 'asaas') return;
    if (typeof window === 'undefined') return;
    if (!window.Boleto) return;
    if (!paymentData?.digitableLine) return;
  
    const line = paymentData.digitableLine.replace(/[^\d]/g, '');
    new window.Boleto(line).toSVG('#boleto');
  }, [paymentData]);

  const handleChangePlan = ({ target: { name, value } }) => {
    setSelectedPlan({ code: value, ...planValues[value] });
  };

  useEffect(() => {
    if (invoice?.planCode != selectedPlan?.code) {
      return setTotalTax(0);
    }

    setTotalTax(invoice?.totalTax);
  }, [selectedPlan]);

  const renderPaymentOptions = () => {
    if (invoice?.id) {
      const { allowedPlans } = subscription;
      return (
        <InputGroup paddingLeft={0} marginBottom="0">
          <InputGroupFlex paddingLeft={0} columns={planValues.length}>
            {allowedPlans.map((plan) => {
              const planValue = planValues?.[plan];
              return (
                planValue &&
                (!invoice?.originalInvoiceId ||
                  (invoice?.originalInvoiceId && invoice?.customData?.planCode == plan)) && (
                  <CardInfo
                    key={plan}
                    label={planValues?.[plan].name}
                    paddingLeft={0}
                    marginLeft="1rem"
                    disabled={invoice.originalInvoiceId && subscription.currentPlan == plan}
                    infoContainer={
                      <Checkbox
                        key={plan}
                        name={`new_plan_${plan}`}
                        label={formatNumberToReal(Number(planValues?.[plan].price).toFixed(2))}
                        value={plan}
                        marginBottom={0}
                        checked={selectedPlan.code === plan}
                        handleChange={handleChangePlan}
                      />
                    }
                  />
                )
              );
            })}
          </InputGroupFlex>
        </InputGroup>
      );
    }
  };

  const renderPaymentForms = () => {
    if (invoice?.id) {
      const { payableWith } = invoice;
      const isAsaas = payableWith.includes("CREDIT_CARD");
      const options = payableWith.split(",");
      const allowedPaymentMethods = subscription.allowedPaymentMethods;

      return (
        allowedPaymentMethods && allowedPaymentMethods.length > 0 ? (
        <InputGroup paddingLeft="2rem" marginBottom="0">
        {(isAsaas
          ? options.includes("CREDIT_CARD")
          : options.includes("credit_card")
        ) && allowedPaymentMethods.includes(2) && (
            <InputGroupFlex paddingLeft={0} columns={1} marginBottom="2rem">
              <RadioButton
                name="idPaymentMethod"
                label="Cartão de Crédito"
                id="creditCard"
                value={2}
                checked={paymentMethod === "2"}
                disabled={!scriptLoaded}
                onClick={(el) => setPaymentMethod(el.target.value)}
                onChange={(el) => setPaymentMethod(el.target.value)}
              />
            </InputGroupFlex>
          )}
          {(isAsaas
            ? options.includes("PIX")
            : options.includes("pix")
          ) && allowedPaymentMethods.includes(3) && (
            <InputGroupFlex paddingLeft={0} columns={1} marginBottom="2rem">
              <RadioButton
                name="idPaymentMethod"
                label="Pix"
                id="pix"
                value={3}
                checked={paymentMethod === "3"}
                onClick={(el) => setPaymentMethod(el.target.value)}
                onChange={(el) => setPaymentMethod(el.target.value)}
              />
            </InputGroupFlex>
          )}
          {(isAsaas
            ? options.includes("BOLETO")
            : options.includes("bank_slip")
          ) && allowedPaymentMethods.includes(1) && (
            <InputGroupFlex paddingLeft={0} columns={1} marginBottom="2.8rem">
              <RadioButton
                name="idPaymentMethod"
                label={`Boleto Bancário (Acréscimo de ${formatNumberToReal(
                  Number(invoice?.billetCostTax?.price).toFixed(2),
                )})`}
                id="bankSlip"
                value={1}
                checked={paymentMethod === "1"}
                onClick={(el) => setPaymentMethod(el.target.value)}
                onChange={(el) => setPaymentMethod(el.target.value)}
              />
            </InputGroupFlex>
          )}
        </InputGroup>
        ) : (
          <p style={{ color: "red", fontSize: '16px', padding: '2rem' }}>Nenhum método de pagamento disponível</p>
        )
      );
    }
  };

  return (
    <>
      {feedback && <Feedback {...feedback} />}
      {!feedback && invoice?.id && !showPixForm && !showBilletForm && (
        <>
          <Columns marginBottom="1rem">
            <Subtitle>Opções de pagamento</Subtitle>
            {renderPaymentOptions()}
          </Columns>
          <Columns>
            <Subtitle>Confira os dados da fatura</Subtitle>
            <CardInfo label="Nome da Empresa" info={enterprise?.enterpriseName} />
            <CardInfo label="CNPJ" info={enterprise?.cnpj} />
            <Columns columns={2}>
              <CardInfo label="Data de Vencimento" info={formatDate(invoice?.dueDate)} />
              <CardInfo
                label="Valor do Plano"
                info={formatNumberToReal(
                  hasItemBillet
                    ? Number(selectedPlan?.price) - Number(invoice?.billetCostTax?.price)
                    : Number(selectedPlan?.price),
                )}
              />
            </Columns>
            <Columns columns={2} marginBottom="2rem">
              {paymentMethod === "1" && (
                <>
                  <CardInfo
                    label="Acréscimo boleto"
                    info={formatNumberToReal(Number(invoice?.billetCostTax?.price).toFixed(2))}
                  ></CardInfo>
                </>
              )}
             <CardInfo
                label="Multas/Juros (+)"
                info={formatNumberToReal(Number(totalTax))}
              />
              <CardInfo
                label="Total"
                info={formatNumberToReal(
                  Number(
                    (Number(
                      Number(selectedPlan?.price) -
                        (hasItemBillet ? parseFloat(invoice?.billetCostTax?.price) : 0),
                    ) + (paymentMethod === "1" ? Number(invoice?.billetCostTax?.price) : 0))
                    + Number(totalTax)
                  ).toFixed(2),
                )}
              />
            </Columns>
            <Columns marginBottom="2rem">
              <Subtitle mb="0.5rem">Forma de pagamento</Subtitle>
              {renderPaymentForms()}
            </Columns>
            <RowFlex horizontalAlign="center">
              <InputGroup paddingLeft={0} style={{ maxWidth: "30rem" }}>
                {invoice?.status === "pending" && (
                  <Button handleClick={handleNext} disabled={!paymentMethod} width="100%">
                    Continuar
                  </Button>
                )}
              </InputGroup>
            </RowFlex>
          </Columns>
        </>
      )}
      {invoice?.id && paymentData && showPixForm && (
        <>
          {paymentData?.qrcode ? (
            <FlexColumn horizontalAlign="center" marginTop="0">
              <Subtitle>Pagamento via Pix</Subtitle>
              <RowFlex marginBottom="1rem" width="95%">
                <Span textAlign="justify">
                  Procure em seu aplicativo de banco ou conta digital a funcionalidade e escaneie o QR
                  Code abaixo ou copie o código usando o Pix Copia e Cola para efetuar o pagamento.
                </Span>
              </RowFlex>
              <img
                src={paymentGateway && paymentGateway?.code == 'asaas' ? `data:image/png;base64,${paymentData?.qrcode}` : paymentData?.qrcode}
                alt="invoice qrcode"
                width={300}
                style={{ marginTop: "1rem" }}
              />
              <Button
                handleClick={() => handleClipboard(paymentData?.qrcodeText)}
                disabled={!invoice}
                width="70%"
                marginTop="2rem"
              >
                Pix Copia e Cola - Copiar código
              </Button>
              {copied && <Label label="Código copiado com sucesso!" color="#0071ee" />}
            </FlexColumn>
          ) : (
            <FlexColumn horizontalAlign="center" marginTop="0">
              <Subtitle>Pagamento via Pix Indisponível!</Subtitle>
              <RowFlex marginBottom="1rem" width="95%">
                <Span textAlign="justify">
                  Não foi possível gerar o QR Code para pagamento via Pix. <br/> Isso pode ocorrer porque 
                  o Pix está indisponível no momento ou porque a sua fatura já foi paga. Verifique se a sua fatura 
                  já foi paga ou tente novamente mais tarde.
                </Span>
              </RowFlex>
            </FlexColumn>
          )}
          <Flex horizontalAlign="center">
            <Button handleClick={() => setShowPixForm(false)}>Voltar</Button>
          </Flex>
        </>
      )}

      {invoice?.id && paymentData && showBilletForm && (
        <>
          <FlexColumn horizontalAlign="center">
            <Subtitle>Pagamento via Boleto Bancário</Subtitle>
            {isMobile && (
              <RowFlex marginBottom="1rem" width="95%">
                <Span textAlign="justify">
                  Copie o código da linha digitável abaixo e cole em seu aplicativo de banco ou
                  conta digital, para efetuar o pagamento.
                </Span>
              </RowFlex>
            )}
            {!isMobile && (
              <>
                <RowFlex marginBottom="1rem" width="95%">
                  <Span textAlign="justify">
                    Leia o código de barras usando seu aplicativo de banco ou conta digital, ou
                    copie o código da linha digitável abaixo e cole em seu aplicativo de banco ou
                    conta digital, para efetuar o pagamento.
                  </Span>
                </RowFlex>
                {
                  paymentGateway && paymentGateway?.code == 'asaas' ? (
                    <div id="boleto" style={{ marginTop: "3rem", marginBottom: "3rem" }}></div>
                  ) : (
                    <img
                      src={paymentData?.barcode}
                      alt="invoice barcode"
                      style={{ marginTop: "3rem", marginBottom: "3rem" }}
                    />
                  )
                }
              </>
            )}
            <Span
              style={{ fontSize: "12px" }}
              onClick={() => handleClipboard(paymentData?.digitableLine)}
            >
              {paymentData?.digitableLine}
            </Span>
            <Button
              handleClick={() => handleClipboard(paymentData?.digitableLine)}
              disabled={!paymentData?.digitableLine}
              width="70%"
              marginRight="0"
              marginTop="1rem"
            >
              Copiar linha digitável
            </Button>
            {copied && <Label label="Código copiado com sucesso!" color="#0071ee" />}
            <Button
              handleClick={handleDownload}
              width="70%"
              disabled={!paymentData?.barcode}
              marginRight="0"
              marginTop="1rem"
            >
              {isDownloading && <Spinner height="3rem" width="3rem" marginRight="1rem" />}
              Baixar fatura
            </Button>
            {isDownloading && (
              <Label label="Aguarde! Iniciando download do arquivo" color="#0071ee" />
            )}
            {isDownloaded && <Label label="Download concluído" color="#0071ee" />}
            <Button
              handleClick={() => window.open(paymentData?.invoiceUrl)}
              width="70%"
              disabled={!paymentData?.barcode}
              marginRight="0"
              marginTop="1rem"
            >
              Visualizar fatura
            </Button>
          </FlexColumn>
          <Flex horizontalAlign="center">
            <Button variant="outline" handleClick={() => setShowBilletForm(false)} width="70%">
              Voltar
            </Button>
          </Flex>
        </>
      )}

      {
        invoicePayment && invoicePayment.error?.response && (
          invoicePayment.error?.response?.data?.message.includes("quitada") &&
          <FlexColumn horizontalAlign="center">
            <Label label={'Fatura já consta como paga!'} color="red" style={{ fontSize: "32px" }} />
          </FlexColumn>
        )
      }
    </>
  );
};

Payment.propTypes = {
  paymentData: PropTypes.object,
  feedback: PropTypes.object,
  formatDate: PropTypes.func,
  handleNext: PropTypes.func.isRequired,
  invoice: PropTypes.object.isRequired,
  paymentMethod: PropTypes.string,
  setPaymentMethod: PropTypes.func.isRequired,
  selectedPlan: PropTypes.object,
  setSelectedPlan: PropTypes.func,
  // handleChangePlan: PropTypes.func,
};

Payment.defaultProps = {
  feedback: null,
};

export default Payment;
