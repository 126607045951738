import { combineReducers } from "redux";
import address from "./address";
import auth from "./auth";
import client from "./client";
import chat from "./chat";
import documents from "./documents";
import emitter from "./emitter";
import enterprise from "./enterprise";
import layout from "./layout";
import invoice from "./invoice";
import notification from "./notification";
import password from "./password";
import payment from "./payment";
import paymentGateways from "./paymentGateways";
import roles from "./roles";
import services from "./services";
import products from "./products";
import cst from "./cst";
import ncm from "./ncm";
import table from "./table";
import taxes from "./taxes";
import users from "./users";
import cfop from "./cfop";
import ncmGlobals from "./ncmGlobals";
import cities from "./cities";
import coupons from "./coupons";
import checkFields from "./checkFields";
import cnpjChecker from "./cnpjChecker";
import batches from "./batches";
import subscriptions from "./subscriptions";
import email from "./emails";
import reports from "./reports";
import invoicePayment from "./invoicePayment";
import financial from "./financial";
import financialSubscriptions from "./financialSubscriptions";
import inter from "./interbank";
import paramsEmiiter from "./paramsEmitter";
import params from "./params";
import tasks from "./tasks";

export default combineReducers({
  address,
  auth,
  client,
  chat,
  checkFields,
  cnpjChecker,
  documents,
  emitter,
  enterprise,
  layout,
  invoice,
  notification,
  password,
  payment,
  paymentGateways,
  roles,
  services,
  products,
  cst,
  ncm,
  table,
  taxes,
  users,
  cfop,
  ncmGlobals,
  cities,
  coupons,
  batches,
  subscriptions,
  email,
  reports,
  invoicePayment,
  financial,
  financialSubscriptions,
  inter,
  paramsEmiiter,
  params,
  tasks,
});
