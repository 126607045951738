import React from "react";
import PropTypes from "prop-types";
import * as S from "./styled";

export const StatusTypes = {
  SUCCESS: "var(--color-success)",
  ERROR: "var(--color-danger)",
  ALERT: "var(--color-alert)",
  INFO: "var(--color-primary)",
  WARNING: "var(--color-alert)",
  PROCESSING: "var(--color-purple-1)",
  CANCELED: "var(--color-gray-1)",
  DONE: "var(--color-gray-5)",
};

const StatusDot = ({ type }) => <S.Dot type={type} />;

StatusDot.propTypes = {
  type: PropTypes.string.isRequired,
};

export default StatusDot;
