import React, { useState } from "react";
import { SelectWithDot } from "components/core/SelectForm";
import { Form } from "components/core/Form";
import { Flex } from "components/core/Grid";
import { ModalActions } from "components/core/Modal/styled";
import { Spinner } from "../WithSpinner/styled";
import { useReload } from "hooks";
import Modal from "components/core/Modal";
import InputForm from "components/core/InputForm";
import Button from "components/core/Button";
import toast from "react-hot-toast";

const ModalWebhookFilter = ({ showModal, onCloseModal }) => {
  const { getWebhooks, setData, setFilters } = useReload();
  const [loading, setLoading] = useState(false);
  
  const verifyFiltersQuantity = async (data) => {
    let quantity = 0;
    if (data?.name) quantity++;
    if (data?.status != -1) quantity++;
    setFilters(quantity);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData(e.target);
      const data = Object.fromEntries(formData.entries());
      if (!data?.name && data?.status == -1) {
        toast.error("Preencha pelo menos um filtro!", { position: "top-right" });
        setLoading(false);
        return;
      }
  
      const response = await getWebhooks({
        ...(data.name && { name: data.name }),
        ...data?.status != -1 && { status: data?.status },
      });
      await verifyFiltersQuantity(data);
      if (!response) setData([]);
      onCloseModal();
    } catch (error) {
      await verifyFiltersQuantity();
      toast.error("Ocorreu um erro ao filtrar!");
    } finally {
      setLoading(false);
    }
  }
  return (
    <Modal
      title="Filtrar Webhooks"
      confirmLabel="Filtrar"
      onCloseModal={() => onCloseModal()}
      showModal={showModal}
      textAlign="text-left"
      showActions={false}
    >
      <Flex verticalAlign="center">
        <Form onSubmit={handleSubmit}>
          <InputForm
            type="text"
            name="name"
            label="Nome"
            placeholder="Digite um nome..."
          />
          <SelectWithDot
            id="status"
            name="status"
            label="Status"
            mb="3.2rem"
            options={[
              { value: -1, text: "Selecione um Status" },
              { value: 1, text: "Ativo" },
              { value: 0, text: "Inativo" },
            ]}
          />
          <ModalActions>
            <Button variant="outline" handleClick={onCloseModal}>
              Cancelar
            </Button>
            <Button type="submit">
              {
                loading ? (
                  <Spinner margin={'auto'} width={'2rem'} height={'2rem'}/>
                ) : "Filtrar"
              }
            </Button>
          </ModalActions>
        </Form>
      </Flex>
    </Modal>
  );
};

export default ModalWebhookFilter;