import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CardInfo from "components/core/CardInfo";
import { Subtitle, Divider } from "components/core/Typography";
import { Container, Columns, RowFlex } from "components/core/Grid";
import { formatDate, formatNumberToReal } from "helpers/format";
import { LINKS } from "utils/constants/urls";
import Button from "components/core/Button";
import { Table, TableHeader, TableBody, TableItem, RenderHeaders } from "components/core/Table";
import { InputGroup } from "components/core/Form";
import { Label } from "components/core/InputForm/styled";
import SelectForm from "components/core/SelectForm";

const EmitterDetails = ({ emitter, statusLog, subscriptions }) => {
  const [isMEI, setISMEI] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [activeSubscriptions, setActiveSubscriptions] = useState(null);
  const [selectedSubscriptionFirstPayment, setSelectedSubscriptionFirstPayment] = useState(null);
  const { t: translate } = useTranslation();
  const {
    type: { name: enterpriseTypeName },
    paymentToken,
  } = emitter;

  const proLaboresForLatestCompetence = {
    amount: 0,
  };

  const countProLaboresForLatestCompetence = (currentEmitterDetails) => {
    const { proLabores = [] } = currentEmitterDetails ?? {};
    if (proLabores.length === 0) {
      return;
    }
    const maxTimestamp = Math.max(
      ...proLabores.map((item) => new Date(item.competenceDate).getTime()),
    );
    const latestProLabores = proLabores.filter(
      (item) => new Date(item.competenceDate).getTime() === maxTimestamp,
    );

    return latestProLabores.length;
  };

  proLaboresForLatestCompetence.amount = countProLaboresForLatestCompetence(emitter);


  useEffect(() => {
    if (subscriptions?.length) {
      const activeSubscription = subscriptions.filter((s) => s.active);

      setActiveSubscriptions(activeSubscription);
      setSelectedSubscription(subscriptions.find((s) => s.active));
    }
  }, [subscriptions]);

  useEffect(() => {
    setSelectedSubscriptionFirstPayment(null);
    if (selectedSubscription && selectedSubscription?.payments?.length) {
      setSelectedSubscriptionFirstPayment(selectedSubscription?.payments?.slice(-1)?.[0]);
    }
  }, [selectedSubscription]);

  useEffect(() => {
    if (emitter?.idEnterpriseType === 1) {
      setISMEI(true);
    }
  }, [emitter?.idEnterpriseType]);

  const { billingMin, billingMax } = emitter.billingRange ?? {};
  const billingValue =
    enterpriseTypeName.toLowerCase() === "mei"
      ? formatNumberToReal(emitter.billing)
      : translate("billingRange", {
          min: formatNumberToReal(billingMin),
          max: formatNumberToReal(billingMax),
        });

  const headers = [
    { slug: "id", title: translate("ID") },
    { slug: "user.name", title: translate("user") },
    { slug: "oldStatus.name", title: translate("Status Anterior") },
    { slug: "status.name", title: translate("Novo Status") },
    { slug: "oldSubStatus.name", title: translate("Sub-Status Anterior") },
    { slug: "subStatus.name", title: translate("Novo Sub-Status") },
    { slug: "createdAt", title: `${translate("Data de Alteração")}` },
  ];

  const paymentLink = (token) => {
    const link = LINKS.paymentLink(token);
    return `${window.location.origin}${link}`;
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setLinkCopied(true);
    setTimeout(() => {
      setLinkCopied(false);
    }, 3000);
  };

  const getPaymentMethod = (paymentMethod) => {
    switch (paymentMethod) {
      case "credit_card":
        return translate("creditCard");
      case "bank_slip":
        return translate("bankSlip");
      case "pix":
        return "PIX";
      default:
        return paymentMethod;
    }
  };

  const selectSubscription = ({ target: { value } }) => {
    const subscription = subscriptions.find((s) => s.id === Number(value));
    console.log(subscription);
    setSelectedSubscription(subscription);
  };

  return (
    <Container>
      <Subtitle>{translate("contact")}</Subtitle>
      <Columns columns="2">
        <CardInfo label={translate("name")} info={emitter.user.name} />
        <CardInfo label={translate("status")} info={emitter.status.name} />
        <CardInfo label={translate("cpf")} info={emitter.user.cpf} />
        <CardInfo label={translate("Sub-Status")} info={emitter?.subStatus?.name} />
        <CardInfo label={translate("dateOfBirth")} info={emitter.user.dateOfBirth} />
        <CardInfo label={translate("financialStatus")} info={emitter.finStatus?.name} />
      </Columns>
      <Columns columns="2">
        <CardInfo label={translate("email")} info={emitter?.user?.email} />
        <CardInfo label={translate("phone")} info={emitter?.user?.phoneNumber} />
      </Columns>
      <Columns columns="2">
        <CardInfo label={translate("billingEmail")} info={emitter?.billingEmail} />
        <CardInfo label={translate("billingPhone")} info={emitter?.billingPhone} />
      </Columns>
      <Columns columns="2">
        <CardInfo
          label={translate("constitution")}
          info={emitter.constitution ? "Sim" : emitter.constitution === null ? "-" : "Não"}
        />
        <CardInfo label={translate("comments")} info={emitter.comments} />
      </Columns>
      <Columns columns="2">
        <CardInfo label={translate("priority")} info={emitter.priority} />
        <CardInfo label={translate("source")} info={emitter.src?.name} />
      </Columns>
      <Columns columns="2">
        <CardInfo label={translate("activityGroupSingular")} info={emitter.actvGroup?.name} />
        <CardInfo label={translate("partnersObvia")} info={emitter.partnerObvia?.name} />
      </Columns>
      <Columns columns="2">
        <CardInfo label={translate("responsibleForSale")} info={emitter.respSale?.name} />
      </Columns>
      <Divider />

      <Subtitle>{translate("address")}</Subtitle>
      <Columns columns="2">
        <CardInfo label={translate("street")} info={emitter.street} />
        <CardInfo label={translate("number")} info={emitter.number} />
        <CardInfo label={translate("neighborhood")} info={emitter.neighborhood} />
        <CardInfo label={translate("complement")} info={emitter.complement} />
      </Columns>
      <Columns columns="2">
        <CardInfo label={translate("city")} info={emitter.city} />
        <CardInfo label={translate("state")} info={emitter.state} />
        <CardInfo label={translate("zipCode")} info={emitter.zipCode} />
      </Columns>
      <Divider />

      <Subtitle>{translate("company")}</Subtitle>
      <Columns columns="2">
        <CardInfo label={translate("enterpriseName")} info={emitter.enterpriseName} />
        <CardInfo label={translate("enterpriseType")} info={emitter.type.name} />
        <CardInfo label={translate("fantasyName")} info={emitter.fantasyName} />
        <CardInfo label={translate("operationsNature")} info={emitter.operationsNature} />
        <CardInfo label={translate("billing")} info={billingValue} />
        <CardInfo label={translate("cnpj")} info={emitter.cnpj} />
        <CardInfo label={translate("employeesNumber")} info={emitter?.numberEmployees} />
        {isMEI === false && (
          <CardInfo
            label={translate("proLaboreNumber")}
            info={proLaboresForLatestCompetence.amount}
          />
        )}
        <CardInfo label={translate("registrationDate")} info={formatDate(emitter.createdAt)} />
        <CardInfo
          label={translate("activityType")}
          info={emitter.activity ? emitter.activity.name : "Não informado ou API IBGE inoperante"}
        />
      </Columns>
      <Columns columns={2}>
        <CardInfo label={translate("stateRegistration")} info={emitter.stateRegistration} />
        <CardInfo label={translate("cityRegistration")} info={emitter.cityRegistration} />
      </Columns>
      <Divider />
      <Subtitle>Informações do primeiro pagamento</Subtitle>
      <Columns columns={3}>
        <CardInfo
          label={translate("Value")}
          info={formatNumberToReal(
            Number(
              !emitter?.firstSubscription?.[0].active
                ? emitter?.firstSubscription?.[0]?.price
                : emitter?.payments?.[0]?.paidAmount,
            ),
          )}
        />
        <CardInfo
          label={translate("date")}
          info={
            !emitter?.firstSubscription[0].active && emitter.firstSubscription?.[0]?.payDate
              ? formatDate(emitter.firstSubscription?.[0]?.payDate)
              : formatDate(emitter?.payments?.[0]?.paymentDate)
          }
        />
        <CardInfo
          label={translate("paymentMethod")}
          info={
            !emitter?.firstSubscription[0].active
              ? emitter.subscription?.[0]?.paymentMethod?.name
              : getPaymentMethod(emitter?.payments?.[0]?.paymentMethod)
          }
        />
      </Columns>
      {paymentToken && (
        <Columns columns="1">
          <InputGroup paddingLeft="0" columns="4" style={{ marginBottom: "0" }}>
            <CardInfo label={translate("Payment Link")} info={paymentLink(paymentToken)} />
            <Button
              style={{ marginTop: "1.6rem" }}
              handleClick={() => copyToClipboard(paymentLink(paymentToken))}
            >
              Copiar link
            </Button>
            {linkCopied && (
              <Label style={{ marginTop: "2rem", fontSize: "1.5rem", color: "#0071ee" }}>
                {translate("Payment Link Copied")}
              </Label>
            )}
          </InputGroup>
        </Columns>
      )}
      <Divider />

      {emitter.status.id !== 8 && selectedSubscription && (
        <>
          <RowFlex horizontalAlign="space-between" verticalAlign="flex-end">
            <Subtitle style={{ marginBottom: "0" }}>
              {`#${selectedSubscription?.id} - ${selectedSubscription?.planName} - ${selectedSubscription?.enterpriseTypeName}`}
            </Subtitle>
            <Columns style={{ maxWidth: "50rem" }} marginBottom={0}>
              <SelectForm
                name="subscriptionId"
                label={`${translate("Subscriptions")}`}
                options={(activeSubscriptions.length > 0
                  ? activeSubscriptions
                  : subscriptions
                )?.map((s) => ({
                  value: s.id,
                  text: `${s.id} - ${s.active ? "Ativa" : "Inativa"} - ${s.enterpriseTypeName} - ${
                    s.planName
                  }`,
                }))}
                marginBottom={0}
                handleChange={selectSubscription}
              />
            </Columns>
          </RowFlex>

          <Columns columns="3">
            <CardInfo
              label={translate("curentInstallmentValue")}
              info={formatNumberToReal(Number(selectedSubscription?.price))}
            />
            <CardInfo
              label={translate("nextPaymentDate")}
              info={formatDate(selectedSubscription?.nextPaymentDate)}
            />
            <CardInfo label={translate("Plan")} info={emitter?.subscription[0]?.paymentPlanType} />
            {/* <CardInfo
              label={translate("Discount Description")}
              info={emitter?.subscription[0]?.discountDescription}
            />
            <CardInfo
              label={translate("Discount Value")}
              info={formatNumberToReal(Number(emitter?.subscription[0]?.discountMonetaryValue))}
            /> */}
          </Columns>
          <Columns columns="3">
            <CardInfo
              label={translate("firstPayment")}
              info={formatNumberToReal(Number(selectedSubscriptionFirstPayment?.paidAmount))}
            />
            <CardInfo
              label={translate("firstPaymentDate")}
              info={formatDate(selectedSubscriptionFirstPayment?.paymentDate)}
            />
            <CardInfo
              label={translate("paymentMethod")}
              info={getPaymentMethod(selectedSubscriptionFirstPayment?.paymentMethod)}
            />
          </Columns>
          {/* <Columns columns="3">
            <CardInfo label={translate("Plan")} info={emitter?.subscription[0]?.paymentPlanType} />
            <CardInfo
              label={translate("nextPaymentDate")}
              info={
                emitter.activePaymentGatewayClientSubscription?.expiresAt
                  ? formatDate(emitter.activePaymentGatewayClientSubscription?.expiresAt)
                  : ""
              }
            />
            <CardInfo
              label={translate("paymentMethod")}
              info={
                emitter.activePaymentGatewayClientSubscription?.paymentGatewayMethod.name ||
                emitter.subscription[0]?.paymentMethod.name
              }
            />
          </Columns> */}

          <Divider />
          {/* <RowFlex horizontalAlign="space-between" marginBottom="2.4rem">
            <Subtitle style={{ marginBottom: "0" }}>{translate("Installments Settings")}</Subtitle>
            <Button url={LINKS.emitterSubscriptionEdit(emitter.id)} variant="outline">
              {translate("edit")}
            </Button>
          </RowFlex>
          <Columns columns="3">
            <CardInfo
              label={`${translate("Inherit Settings")}?`}
              info={
                <>
                  <StatusDot
                    type={
                      installmentSetting?.inheritSettings ? StatusTypes.SUCCESS : StatusTypes.ERROR
                    }
                  />
                  {installmentSetting?.inheritSettings ? "Sim" : "Não"}
                </>
              }
            />
            <CardInfo
              label={`${translate("Allow Installments")}?`}
              info={
                <>
                  <StatusDot
                    type={
                      installmentSetting?.allowInstallments
                        ? StatusTypes.SUCCESS
                        : StatusTypes.ERROR
                    }
                  />
                  {installmentSetting?.allowInstallments ? "Sim" : "Não"}
                </>
              }
            />
            <CardInfo
              label={`${translate("Allow Installments First Subscription")}?`}
              info={
                <>
                  <StatusDot
                    type={
                      installmentSetting?.allowInstallmentsFirstSubscription
                        ? StatusTypes.SUCCESS
                        : StatusTypes.ERROR
                    }
                  />
                  {installmentSetting?.allowInstallmentsFirstSubscription ? "Sim" : "Não"}
                </>
              }
            />
            <CardInfo label={translate("Allowed Plans")} info={plans} />
            <CardInfo
              label={translate("Maximum Installments Number")}
              info={installmentSetting?.maxInstallmentsNumber}
            />
            <CardInfo
              label={translate("Interest")}
              info={
                <>
                  {Number(installmentSetting?.interestType) === 1
                    ? `${installmentSetting?.monthlyInterest}% a.m.`
                    : `${installmentSetting?.annualInterest}% a.a.`}
                </>
              }
            />
          </Columns>
          <Divider /> */}
        </>
      )}
      <>
        <Subtitle>{translate("statusLog")}</Subtitle>
        <Table>
          <TableHeader>
            <RenderHeaders headers={headers} />
          </TableHeader>
          <TableBody>
            {statusLog &&
              statusLog.map((log, index) => (
                <tr key={index}>
                  <TableItem>{log.id}</TableItem>
                  <TableItem>{log.user.name}</TableItem>
                  <TableItem>{log.oldStatus.name}</TableItem>
                  <TableItem>{log.status.name}</TableItem>
                  <TableItem>{log?.oldSubStatus?.name || "-"}</TableItem>
                  <TableItem>{log?.subStatus?.name || "-"}</TableItem>
                  <TableItem>
                    {log.createdAt ? formatDate(log.createdAt, false, true, true) : "-"}
                  </TableItem>
                </tr>
              ))}
          </TableBody>
        </Table>
      </>
    </Container>
  );
};

EmitterDetails.propTypes = {
  emitter: PropTypes.object.isRequired,
};

export default EmitterDetails;
