import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputForm from "components/core/InputForm";
import { Subtitle } from "components/core/Typography";
import { Container } from "components/core/Grid";
import { Form, InputHidden, InputGroup } from "components/core/Form";
import { InputWrapper, Label, Field, ErrorMsg } from "components/core/InputForm/styled";
import AuthFormFactory from "./AuthFormFactory";
import UploadCertificate from "./UploadCertificate";

const DigitalAuthorization = ({ onSubmit, submitRef, authType, certificate }) => {
  const { t: translate } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [certificateName, setCertificateName] = useState(null);
  const { register, handleSubmit, errors, setValue } = useForm({ mode: "onBlur" });

  const handleUploadCertificate = ({ document }) => {
    const [newCertificate] = document;
    setShowModal(false);
    setValue("certificate", document);
    setCertificateName(newCertificate?.name);
  };

  return (
    <>
      <Container maxWidth="md">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <AuthFormFactory authType={authType} register={register} errors={errors} />
          {certificate && (
            <>
              <Subtitle>{translate("Certificado Digital")}</Subtitle>
              <InputGroup>
                <InputWrapper>
                  <Label>{translate("file")}</Label>
                  <Field type="button" as="button" onClick={() => setShowModal(true)}>
                    {certificateName || translate("Selecionar arquivo")}
                  </Field>
                  <ErrorMsg>{errors?.certificate?.message}</ErrorMsg>
                </InputWrapper>
                <InputHidden
                  ref={register({
                    required: { value: true, message: translate("required.certificate") },
                  })}
                  type="file"
                  name="certificate"
                />
                <InputForm
                  register={register({
                    required: { value: true, message: translate("required.passwordCertificate") },
                  })}
                  type="text"
                  name="passwordCertificate"
                  label={translate("passwordCertificate")}
                  errorMsg={errors.passwordCertificate?.message}
                />
              </InputGroup>
            </>
          )}
          <InputHidden ref={submitRef} type="submit" value="Submit" />
        </Form>
        <UploadCertificate
          onSubmit={handleUploadCertificate}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      </Container>
    </>
  );
};

DigitalAuthorization.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitRef: PropTypes.object.isRequired,
  authType: PropTypes.string.isRequired,
  certificate: PropTypes.bool.isRequired,
};

export default DigitalAuthorization;
