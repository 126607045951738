import React, { useEffect, useState } from "react";
import { Spinner } from "components/core/WithSpinner/styled";
import useReload from "hooks/useReload";
import columns from "./table/columns";
import NavBar from "components/core/NavBar";
import ModalWebhookFilter from "components/core/ModalWebhook";
import DataTable from "./table";
import Button from "components/core/Button";
import ModalCreateWebHook from "components/core/ModalCreateWebhook";
import toast from "react-hot-toast";
import FilterButton from "components/core/FilterButton";

const WebHooks = () => {
  const { reloaded, reloadPage, data, getWebhooks, filters, setFilters } = useReload();
  const [showFilters, setShowFilters] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchAll = async () => {
    try {
      setLoading(true);
      await getWebhooks();
    } catch (error) {
      toast.error("Ocorreu um erro ao buscar os webhooks");
    } finally {
      setLoading(false);
    }
  };
  const handleClearFilters = () => {
    setFilters(0);
    reloadPage();
  }
  
  useEffect(() => {
    fetchAll();
  }, [reloaded]);

  return (
    <>
      <NavBar title={"Webhooks"}>
        <FilterButton counterFilters={filters} handleClearFilters={handleClearFilters} handleShowFilter={() => setShowFilters(true)} />
        <Button variant="primary" handleClick={() => setShowModal(true)}>
          Criar
        </Button>
      </NavBar>
      <ModalWebhookFilter showModal={showFilters} onCloseModal={() => setShowFilters(false)} />
      <ModalCreateWebHook showModal={showModal} onCloseModal={() => setShowModal(false)} />
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", height: "100%", width: "100%" }}>
          <Spinner style={{ margin: "auto" }} />
        </div>
      ) : (
        <DataTable params={data} columns={columns} />
      )}
    </>
  );
};

export default WebHooks;
