import React, { createContext, useContext, useState } from "react";
import api from "services/api";
export const ReloadContext = createContext();

export const ReloadProvider = ({ children }) => {
  const [reloaded, setReloaded] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState(0);
  const [data, setData] = useState([]);
  const reloadPage = () => setReloaded(!reloaded);

  const reset = () => {
    setPage(1);
    setPerPage(10);
    setFilters(0);
  }
  
  const getWebhooks = async (filter) => {
    try {
      const response = await api.get('/webhooks', (filter || perPage || page) && { params: { ...filter, page, perPage } });
      setData(response.data);
      return response.data;
    } catch (error) {
      setData([]);
      return error;
    }
  }

  return (
    <ReloadContext.Provider value={{ reloaded, reloadPage, data, setData, getWebhooks, filters, setFilters, page, setPage, perPage, setPerPage, reset}}>
      {children}
    </ReloadContext.Provider>
  )
}

const useReload = () => {
  return useContext(ReloadContext);
}

export default useReload;