import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "hooks";
import NavBar from "components/core/NavBar";
import { TaskActions } from "store/ducks/tasks";
import WithSpinner from "components/core/WithSpinner";
import WithSuspense from "components/core/WithSuspense";
import List from "components/presentation/Tasks/List";

const Filter = React.lazy(() => import("containers/Tasks/Filter"));

const TasksListWithSpinner = WithSpinner(List);
const FilterWithSuspense = WithSuspense(Filter);

const TasksListContainer = () => {
  const dispatch = useDispatch();
  const [showFilters, setShowFilters] = useState(false);
  const {
    TasksRequestsStatus,
    tasks: { items, total, totalPages },
  } = useSelector(({ tasks }) => tasks);
  const fetchTasks = (newParams) => {
    dispatch(TaskActions.fetchTasks(newParams));
  };
  const {
    orderBy,
    order,
    page,
    perPage,
    setPage,
    setPerPage,
    setOrderBy,
    setOrder,
    handleFilter,
    SmartFilterButton,
  } = useParams({
    callback: fetchTasks,
    defaultOrderBy: "id",
  });

  return (
    <>
      <NavBar title="Controle de Tarefas">
        <SmartFilterButton handleShowFilter={() => setShowFilters(true)} />
      </NavBar>
      <TasksListWithSpinner
        requestStatus={TasksRequestsStatus.FETCH_TASKS}
        tasks={items}
        setPage={setPage}
        total={total}
        totalPages={totalPages}
        page={page}
        perPage={perPage}
        setOrderBy={setOrderBy}
        setPerPage={setPerPage}
        setOrder={setOrder}
        order={order}
        orderBy={orderBy}
      />
      <FilterWithSuspense
        loadComponent={showFilters}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        dispatchWithParams={handleFilter}
      />
    </>
  );
};

export default TasksListContainer;
