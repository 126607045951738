import React, { useRef, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { EmitterActions } from "store/ducks/emitter";
import { LayoutActions } from "store/ducks/layout";
import { Span } from "components/core/Typography";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import WithSpinner from "components/core/WithSpinner";
import EditRPSComponent from "components/presentation/Invoice/RPS";
import { useRequest } from "hooks";

const PresentationWithSpinner = WithSpinner(EditRPSComponent);

const RPSContainer = () => {
  const submitRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const { emitterId } = useParams();
  const { t: translate } = useTranslation();
  const { statusOfRequests, emitterDetails } = useSelector(({ emitter }) => emitter);
  const [types, setTypes] = useState([]);

  const handleSubmit = (values) => dispatch(EmitterActions.editSerialNumber(emitterId, values));

  useEffect(() => {
    dispatch(EmitterActions.fetchEmitterById(emitterId));
  }, [dispatch, emitterId]);

  const onResolved = () => {
    dispatch(
      LayoutActions.showConfirmationModal({
        content: (
          <Span>
            <Trans
              i18nKey="Tudo Certo!"
              values="RPS foi alterado com sucesso"
              components={{ bold: <strong /> }}
            />
          </Span>
        ),
        type: "success",
      }),
    );
    dispatch(EmitterActions.cleanRequest());
    history.push("/");
  };

  useRequest({
    request: statusOfRequests.EDIT_SERIAL_NUMBER,
    onResolved,
  });

  useEffect(() => {
    const typesFromEmitter = emitterDetails?.enterpriseInvoiceType?.map(({ invoiceType }) => ({
      ...invoiceType,
    }));
    setTypes(typesFromEmitter);
  }, [emitterDetails?.enterpriseInvoiceType]);

  return (
    <>
      <NavBar title={translate("editRPSInformation")}>
        <Button variant="outline" handleClick={() => history.goBack()}>
          <span>{translate("back")}</span>
        </Button>
        <Button handleClick={() => submitRef.current.click()}>
          <span>{translate("save")}</span>
        </Button>
      </NavBar>

      <PresentationWithSpinner onSubmit={handleSubmit} options={types} submitRef={submitRef} />
    </>
  );
};

export default RPSContainer;
