import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Small } from "components/core/Typography";
import * as S from "./styled";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const range = (from, to, step = 1) => {
  let i = from;
  const rangeData = [];

  while (i <= to) {
    rangeData.push(i);
    i += step;
  }

  return rangeData;
};

const PaginationButton = ({ page, handleClick, isActive }) => (
  <S.PaginationButton type="button" fontWeight={isActive && "bold"} onClick={handleClick}>
    {page}
  </S.PaginationButton>
);

const Pagination = ({
  perPage,
  totalItems,
  actualPage,
  lastPage,
  setPage,
  handleChange,
  views,
}) => {
  const [pageNeighbours] = useState(2);

  const options = [
    { value: 10, text: "10", selected: perPage === 10 && "selected" },
    { value: 40, text: "40", selected: perPage === 40 && "selected" },
    { value: 70, text: "70", selected: perPage === 70 && "selected" },
    { value: 100, text: "100", selected: perPage === 100 && "selected" },
    { value: totalItems, text: totalItems, selected: perPage === totalItems && "selected" },
  ];

  const firstItemIndex = actualPage === 1 ? 1 : perPage * (actualPage - 1) + 1;
  let visibleItems = firstItemIndex + perPage - 1;
  visibleItems = visibleItems <= totalItems ? visibleItems : totalItems;

  const fetchPageNumbers = useCallback(() => {
    const totalPages = lastPage;

    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, actualPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, actualPage + pageNeighbours);
      let pages = range(startPage, endPage);

      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }
    return range(1, totalPages);
  }, [actualPage, lastPage, pageNeighbours]);

  const pages = useMemo(() => fetchPageNumbers(), [fetchPageNumbers]);

  const updatePage = (event) => {
    handleChange(event);
    setPage(1);
  };

  return (
    <S.PaginationContainer>
      <Small>
        {firstItemIndex < visibleItems ? firstItemIndex : visibleItems }-{visibleItems} de {totalItems}
      </Small>
      <div>
        <S.PaginationButton
          type="button"
          fontWeight="bold"
          onClick={() => setPage(actualPage - 1)}
          disabled={actualPage - 1 < 1}
        >
          Anterior
        </S.PaginationButton>
        {pages !== undefined &&
          pages.map((number, index) => {
            if (number === LEFT_PAGE) return <React.Fragment key={number} />;

            if (number === RIGHT_PAGE) return <React.Fragment key={number} />;

            return (
              <PaginationButton
                key={`btnPage_${index}`}
                page={number}
                isActive={actualPage === number}
                handleClick={() => setPage(number)}
              />
            );
          })}
        <S.PaginationButton
          type="button"
          fontWeight="bold"
          onClick={() => setPage(actualPage + 1)}
          disabled={actualPage + 1 > lastPage}
        >
          Próximo
        </S.PaginationButton>
      </div>
      {views && (
        <S.ViewBox>
          <S.ViewText>Mostrar</S.ViewText>
          <S.ViewSelect onChange={updatePage}>
            {options?.map(({ text, value, selected, disabled: disabledOption }) => (
              <option value={value} key={text} selected={selected} disabled={disabledOption}>
                {text}
              </option>
            ))}
          </S.ViewSelect>
        </S.ViewBox>
      )}
    </S.PaginationContainer>
  );
};

PaginationButton.propTypes = {
  page: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

Pagination.propTypes = {
  perPage: PropTypes.number,
  totalItems: PropTypes.number,
  actualPage: PropTypes.number,
  lastPage: PropTypes.number,
  setPage: PropTypes.func,
  handleChange: PropTypes.func,
  views: PropTypes.bool,
};

Pagination.defaultProps = {
  perPage: 0,
  totalItems: 0,
  actualPage: 1,
  lastPage: 1,
  setPage: null,
  handleChange: null,
  views: false,
};

export default Pagination;
