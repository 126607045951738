import React, { useEffect, useState, memo } from "react";
import PropTypes from "prop-types";
import { useForm, FormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InputForm from "components/core/InputForm";
import SelectForm from "components/core/SelectForm";
import { Subtitle } from "components/core/Typography";
import { Container, FlexColumn, Columns } from "components/core/Grid";
import { Form, InputHidden, InputGroup } from "components/core/Form";
import FormEntepriseTypeObserver from "components/core/Form/FormEntepriseTypeObserver";
import FormPlansParamsObserver from "components/core/Form/FormPlansParamsObserver";
import { isPending, isInProgress } from "components/core/WithSpinner";
import { MASK_TYPES } from "utils/masks";
import { emptyArray } from "helpers/arrays";
import { formatNumberToReal, mapToSelectOptions, formatDate } from "helpers";
import { validateMaxBilling } from "utils/schemas/subscription";
import { parseMoneyToFloat } from "helpers/sanitize";
import { getTaxByEnum, taxesEnum } from "utils/constants/enums";
import Modal from "components/core/Modal";
import { Table, TableHeader, TableBody, TableItem, RenderHeaders } from "components/core/Table";

const employeesOptions = emptyArray(11).map((_, idx) => ({
  text: idx,
  value: idx,
}));

const numberOwnersOptions = emptyArray(60).map((_, idx) => ({
  text: idx,
  value: idx,
}));

const Subscription = ({
  onSubmit,
  defaultValues,
  validationSchema,
  submitRef,
  types,
  billingRanges,
  isMEI,
  handleSetIsMEI,
  subscriptionPlans,
  planValue,
  generalTaxes,
  paymentMethods,
  paymentGateways,
  setPlanValue,
  oldIdRange,
  numberProlabore,
  numberEmployees,
  pendingInvoices,
  oldPlanValue,
  globalSetting,
  enterpriseInstallmentSetting,
  activeSubscriptionId,
}) => {
  const [maxBillingMEI, setMaxBillingMEI] = useState(0);
  const { t: translate } = useTranslation();
  const [firstTimeAfterPlanUpdate, setFirstTimeAfterPlanUpdate] = useState(true);
  const [maxBillingMessage, setMaxBillingMessage] = useState([]);
  const [typeEnterprise, setTypeEnterprise] = useState(null);
  const [typeSubscription, setTypeSubscription] = useState(null);
  const { EnterpriseRequests } = useSelector(({ enterprise }) => enterprise);
  const { PaymentRequests } = useSelector(({ payment }) => payment);
  const [showPendingInvoices, setShowPendingInvoices] = useState(false);
  const [invoicesAction, setInvoicesAction] = useState([]);
  const [invoicesActionsValid, setInvoicesActionsValid] = useState(false);
  const [submitValues, setSubmitValues] = useState(null);
  const [discountValue, setDiscountValue] = useState(null);
  const [liquidPrice, setLiquidPrice] = useState(null);

  const headers = [
    { slug: "dueDate", title: translate("Due Date") },
    { slug: "price", title: translate("Value") },
    { slug: "actions", title: translate("Actions") },
  ];

  const { register, handleSubmit, errors, watch, reset, setValue, ...methods } = useForm({
    validationSchema,
    defaultValues: {
      ...defaultValues,
      newEnterprise: false,
    },
    mode: "onBlur",
  });

  useEffect(() => {
    if (pendingInvoices && pendingInvoices.length > 0) {
      pendingInvoices.forEach((invoice) => {
        setInvoicesAction((state) => {
          const newState = [
            ...state,
            {
              id: invoice.id,
              paymentInvoiceId: invoice.paymentGatewaySubscriptionInvoiceId,
              action: null,
            },
          ];
          const valid = newState.every((invoice) => invoice.action !== null);
          setInvoicesActionsValid(valid);
          return newState;
        });
      });
    }
  }, [pendingInvoices]);

  const watchBilling = watch("billing");
  const watchIdPaymentMethod = watch("idPaymentMethod");

  useEffect(() => {
    if (billingRanges[Number(typeEnterprise)]?.length) {
      let prevBillingRange = billingRanges[Number(typeEnterprise)].find(
        ({ idRange }) => idRange === oldIdRange,
      );

      if (typeEnterprise === 4) {
        prevBillingRange = billingRanges[Number(typeEnterprise)].find(
          ({ idRange }) => idRange === 1,
        );
      }

      setValue("idBillingRange", prevBillingRange?.id || 1);
    }
  }, [billingRanges, oldIdRange, setValue, typeEnterprise]);

  useEffect(() => {
    const meiMaxBilling = getTaxByEnum(generalTaxes)(taxesEnum.meiMaximumMonthBilling);
    setMaxBillingMEI(meiMaxBilling?.price ? parseMoneyToFloat(meiMaxBilling.price) : 0);
  }, [generalTaxes]);

  useEffect(() => {
    if (watchBilling && maxBillingMEI > 0) {
      validateMaxBilling(parseMoneyToFloat(watchBilling), maxBillingMEI, (error) =>
        setMaxBillingMessage(error),
      );
    }
  }, [watchBilling, maxBillingMEI]);

  useEffect(() => {
    if (
      firstTimeAfterPlanUpdate &&
      types.length &&
      subscriptionPlans.length &&
      paymentMethods.length
    ) {
      reset();
      setFirstTimeAfterPlanUpdate(false);
    }
  }, [types, subscriptionPlans, paymentMethods, firstTimeAfterPlanUpdate, reset]);

  useEffect(() => {
    const currentPlan = subscriptionPlans.find(
      ({ idSubscriptionPlan: idSubscriptionPlanParam }) =>
        idSubscriptionPlanParam === typeSubscription,
    );

    if (currentPlan) {
      const {
        idPayroll,
        idBilletCost,
        idAnnuityDiscount,
        idOpeningTax,
        idCalculationTaxMei,
        total,
        billetCost,
        discountValue,
        liquidPrice,
      } = currentPlan;
      const price = Number(watchIdPaymentMethod) === 1 ? total + billetCost : total;
      setValue("idPayroll", idPayroll);
      setValue("idBilletCost", idBilletCost);
      setValue("idAnnuityDiscount", idAnnuityDiscount);
      setValue("idOpeningTax", idOpeningTax);
      setValue("idCalculationTaxMei", idCalculationTaxMei);
      setValue("price", price);
      setPlanValue(price);
      setDiscountValue(discountValue);
      setLiquidPrice(Number(watchIdPaymentMethod) === 1 ? liquidPrice + billetCost : liquidPrice);
    }
  }, [
    subscriptionPlans,
    watchIdPaymentMethod,
    setValue,
    setPlanValue,
    setDiscountValue,
    setLiquidPrice,
    typeSubscription,
  ]);

  useEffect(() => {
    const type = watch("idEnterpriseType");
    setTypeEnterprise(Number(type));
  }, [watch]);

  useEffect(() => {
    const type = watch("idSubscriptionPlan");
    setTypeSubscription(Number(type));
  }, [watch]);

  // useEffect(() => {
  //   if (!enterpriseInstallmentSetting) {
  //     setValue("allowInstallments", null);
  //     setValue("maxInstallmentsNumber", 1);
  //     setValue("interestType", 1);
  //     setValue("interestValue", null);
  //     setValue("allowedInstallmentsPlanMonthly", null);
  //     setValue("allowedInstallmentsPlanAnnual", null);
  //     const inherited = watch("inheritSettings");
  //     if (!globalSetting) {
  //       setValue("inheritSettings", "false");
  //     } else {
  //       if (inherited == "true") {
  //         setValue("allowInstallments", globalSetting?.allowInstallments);
  //         setValue("maxInstallmentsNumber", globalSetting?.maxInstallmentsNumber);
  //         setValue("interestType", globalSetting?.interestType);
  //         setValue(
  //           "interestValue",
  //           Number(globalSetting?.interestType) === 1
  //             ? globalSetting?.monthlyInterest
  //             : globalSetting?.annualInterest,
  //         );
  //         setValue(
  //           "allowedInstallmentsPlanMonthly",
  //           globalSetting?.allowedInstallmentsPlans?.includes(1),
  //         );
  //         setValue(
  //           "allowedInstallmentsPlanAnnual",
  //           globalSetting?.allowedInstallmentsPlans?.includes(2),
  //         );
  //       }
  //     }
  //   }
  // }, [watch, defaultValues, globalSetting, enterpriseInstallmentSetting]);

  const handleChangeInvoiceActions = ({ target: { value } }) => {
    const invoiceData = value.split("-");
    const invoice = {
      id: Number(invoiceData[0]),
      paymentInvoiceId: invoiceData[1],
      action: invoiceData[2] === "null" ? null : invoiceData[2],
    };
    setInvoicesAction((state) => {
      const invoices = state.filter((invoice) => invoice.id !== Number(invoiceData[0]));
      const newState = [...invoices, invoice];
      const valid = newState.every((invoice) => invoice.action !== null);
      setInvoicesActionsValid(valid);
      return newState;
    });
  };

  const handlePendingInvoices = () => {
    const valid = invoicesAction.every((invoice) => invoice.action !== null);
    if (valid) {
      setInvoicesActionsValid(true);
      setShowPendingInvoices(false);
      const values = {
        ...submitValues,
        pendingInvoicesActions: invoicesAction,
      };
      onSubmit(values);
    } else {
      alert(translate("selectActionForAllInvoices"));
    }
  };

  const submit = async (values) => {
    if (
      pendingInvoices &&
      pendingInvoices.length > 0 &&
      Number(oldPlanValue) !== Number(values.price)
    ) {
      setSubmitValues(values);
      setShowPendingInvoices(true);
    } else {
      onSubmit(values);
    }
  };

  return (
    <>
      <Container maxWidth="md">
        <FormContext watch={watch} setValue={setValue} {...methods}>
          <Form onSubmit={handleSubmit(submit)}>
            <FlexColumn horizontalAlign="flex-start">
              <Subtitle>{translate("editPlan")}</Subtitle>
              <InputGroup>
                <Columns columns={2}>
                  <FlexColumn style={{ paddingRight: "1rem", marginBottom: "0" }}>
                    <SelectForm
                      register={register}
                      name="idEnterpriseType"
                      label="Tipo de Empresa"
                      options={types.map(({ id: typeID, name }) => ({
                        text: name,
                        value: typeID,
                      }))}
                      isLoading={isPending(EnterpriseRequests.getTypes)}
                    />
                  </FlexColumn>
                  <FlexColumn style={{ paddingLeft: "1rem", marginBottom: "0" }}>
                    <SelectForm
                      register={register}
                      name="idSubscriptionPlan"
                      label="Tipo de Plano"
                      options={mapToSelectOptions(subscriptionPlans, "idSubscriptionPlan", "name")}
                      isLoading={isInProgress(EnterpriseRequests.getSubscriptionPlans)}
                    />
                  </FlexColumn>
                </Columns>
                {isMEI || typeEnterprise === 1 ? (
                  <InputForm
                    register={register}
                    type="text"
                    name="billing"
                    label="Faturamento Mensal"
                    placeholder="Digite o faturamento mensal"
                    errorMsg={maxBillingMessage[0] ?? errors.billing?.message}
                    mask={MASK_TYPES.real}
                    maxLength="12"
                  />
                ) : (
                  <SelectForm
                    register={register}
                    name="idBillingRange"
                    label="Faturamento Mensal"
                    options={mapToSelectOptions(billingRanges[typeEnterprise], "id", "billing")}
                    isLoading={isPending(EnterpriseRequests.getBillingRanges)}
                  />
                )}
                <Columns columns={2}>
                  <FlexColumn style={{ paddingRight: "1rem", marginBottom: "0" }}>
                    <InputForm
                      register={register}
                      label="Quantidade de Funcionários"
                      name="numberEmployees"
                      type="number"
                      value={numberEmployees}
                      disabled
                    />
                  </FlexColumn>
                  <FlexColumn style={{ paddingLeft: "1rem", marginBottom: "0" }}>
                    {isMEI === false && typeEnterprise !== 1 && (
                      <InputForm
                        register={register}
                        label="Quantidade de Pró-labores"
                        name="numberProlabore"
                        type="number"
                        value={numberProlabore}
                        disabled
                      />
                    )}
                  </FlexColumn>
                </Columns>
                <Columns columns={2}>
                  <FlexColumn style={{ paddingRight: "1rem", marginBottom: "0" }}>
                    <SelectForm
                      register={register}
                      name="paymentPlanType"
                      label={`${translate("Plan")}`}
                      options={[
                        { text: "Antigo", value: "Antigo" },
                        { text: "Novo", value: "Novo" },
                      ]}
                    />
                  </FlexColumn>
                  <FlexColumn style={{ paddingLeft: "1rem", marginBottom: "0" }}>
                    <SelectForm
                      register={register}
                      name="idPaymentGateway"
                      label="Gateway de pagamento"
                      options={mapToSelectOptions(paymentGateways, "id", "name")}
                      isLoading={isPending(PaymentRequests.fetchPaymentGateways)}
                    />
                  </FlexColumn>
                </Columns>
                <Columns columns={2}>
                  <FlexColumn style={{ paddingRight: "1rem", marginBottom: "0" }}></FlexColumn>
                  {/* <FlexColumn style={{ paddingLeft: "1rem", marginBottom: "0" }}>
                    <InputForm
                      register={register}
                      type="text"
                      name="nextPaymentDate"
                      label={translate("nextPaymentDate")}
                      mask={MASK_TYPES.date}
                    />
                  </FlexColumn> */}
                </Columns>
                {/* <InputForm
                  register={register}
                  type="text"
                  name="discountDescription"
                  maxLength="50"
                  label={`${translate("Discount Description")}`}
                />
                <Columns columns={2}>
                  <FlexColumn style={{ paddingRight: "1rem", marginBottom: "0" }}>
                    <SelectForm
                      register={register}
                      name="discountType"
                      label={`${translate("Discount Type")}`}
                      options={mapToSelectOptions(optionsDiscount, "value", "text")}
                    />
                  </FlexColumn>
                  <FlexColumn style={{ paddingLeft: "1rem", marginBottom: "0" }}>
                    <InputForm
                      register={register}
                      type="text"
                      name="discountValue"
                      maxLength="12"
                      label={`${translate("Discount Value")}`}
                      mask={
                        watch("discountType") === "P"
                          ? MASK_TYPES.percentage
                          : MASK_TYPES.realWithName
                      }
                    />
                  </FlexColumn>
                </Columns> */}
                {/* <TertiaryTitle>{translate("Installments Settings")}</TertiaryTitle>
                <Columns columns={2}>
                  <FlexColumn style={{ paddingRight: "1rem", marginBottom: "0" }}>
                    <SelectForm
                      register={register}
                      name="inheritSettings"
                      label={`${translate("Inherit Settings")}?`}
                      options={mapToSelectOptions(optionsYesNo, "value", "text")}
                    />
                  </FlexColumn>
                  <FlexColumn style={{ paddingLeft: "1rem", marginBottom: "0" }}>
                    <SelectForm
                      register={register}
                      name="allowInstallments"
                      label={`${translate("Allow Installments")}?`}
                      options={mapToSelectOptions(optionsYesNo, "value", "text")}
                      disabled={watch("inheritSettings") === "true"}
                    />
                  </FlexColumn>
                </Columns>
                <Columns columns={2}>
                  <FlexColumn style={{ paddingRight: "1rem", marginBottom: "0" }}>
                    <Label>{translate("Allowed Plans")}</Label>
                    <Columns columns="2">
                      <div style={{ marginRight: "1rem" }}>
                        <Checkbox
                          register={register}
                          name="allowedInstallmentsPlanMonthly"
                          label={`${translate("Monthly")}`}
                          disabled={watch("inheritSettings") === "true"}
                        />
                      </div>
                      <div style={{ marginLeft: "1rem" }}>
                        <Checkbox
                          register={register}
                          name="allowedInstallmentsPlanAnnual"
                          label={`${translate("Annual")}`}
                          disabled={watch("inheritSettings") === "true"}
                        />
                      </div>
                    </Columns>
                  </FlexColumn>
                  <FlexColumn style={{ paddingLeft: "1rem", marginBottom: "0" }}>
                    <SelectForm
                      register={register}
                      name="maxInstallmentsNumber"
                      label={`${translate("Maximum Installments Number")}?`}
                      options={mapToSelectOptions(optionsNumber(1, 12), "value", "text")}
                      disabled={watch("inheritSettings") === "true"}
                    />
                  </FlexColumn>
                </Columns>
                <Columns columns={2}>
                  <FlexColumn style={{ paddingRight: "1rem", marginBottom: "0" }}>
                    <SelectForm
                      register={register}
                      name="interestType"
                      label={`${translate("Interest Type")}?`}
                      options={mapToSelectOptions(optionsInterest, "value", "text")}
                      disabled={watch("inheritSettings") === "true"}
                    />
                  </FlexColumn>
                  <FlexColumn style={{ paddingLeft: "1rem", marginBottom: "0" }}>
                    <InputForm
                      register={register}
                      type="text"
                      name="interestValue"
                      label={translate("Interest Value")}
                      disabled={watch("inheritSettings") === "true"}
                      mask={MASK_TYPES.percentage}
                    />
                  </FlexColumn>
                </Columns> */}

                <InputHidden name="newEnterprise" ref={register} />
                <InputHidden name="idPayroll" ref={register} />
                <InputHidden name="idBilletCost" ref={register} />
                <InputHidden name="idAnnuityDiscount" ref={register} />
                <InputHidden name="idOpeningTax" ref={register} />
                <InputHidden name="idCalculationTaxMei" ref={register} />
                <InputHidden name="price" ref={register} />
              </InputGroup>
              {/* <Columns columns={3}>
                <CardInfo label="Valor da Parcela" info={formatNumberToReal(planValue)} />
                <CardInfo label="Desconto" info={formatNumberToReal(discountValue)} />
                <CardInfo label="Valor Líquido" info={formatNumberToReal(liquidPrice)} />
              </Columns> */}
              <InputHidden ref={submitRef} type="submit" value="Submit" />
            </FlexColumn>
          </Form>
          <FormEntepriseTypeObserver
            idEnterpriseType={typeEnterprise}
            types={types}
            callback={handleSetIsMEI}
          />
          <FormPlansParamsObserver
            idEnterpriseType={typeEnterprise}
            isMEI={isMEI || typeEnterprise === 1}
            billingRanges={billingRanges}
            activeSubscriptionId={activeSubscriptionId}
          />
        </FormContext>
      </Container>
      <Modal
        title="O Emissor possui faturas pendentes, o que deseja fazer com elas?"
        confirmLabel="Confirmar"
        onCloseModal={() => setShowPendingInvoices(false)}
        showModal={showPendingInvoices}
        showActions={true}
        width="65rem"
        onConfirmModal={handlePendingInvoices}
        closeOnConfirm={invoicesActionsValid}
        disableConfirm={!invoicesActionsValid}
      >
        <Table>
          <TableHeader>
            <RenderHeaders headers={headers} />
          </TableHeader>
          <TableBody>
            {pendingInvoices?.map(({ id, total, dueDate, paymentGatewaySubscriptionInvoiceId }) => (
              <tr key={id}>
                <TableItem padding={"0 1rem"}>{formatDate(dueDate, 0, false, false)}</TableItem>
                <TableItem padding={"0 1rem"}>{formatNumberToReal(total)}</TableItem>
                <TableItem padding={"0 1rem"}>
                  <SelectForm
                    key={id}
                    name="invoicesAction"
                    handleChange={handleChangeInvoiceActions}
                    marginBottom={"1rem"}
                    options={[
                      {
                        value: `${id}-${paymentGatewaySubscriptionInvoiceId}-null`,
                        text: "Selecione uma ação",
                      },
                      {
                        value: `${id}-${paymentGatewaySubscriptionInvoiceId}-cancel`,
                        text: "Cancelar",
                      },
                      {
                        value: `${id}-${paymentGatewaySubscriptionInvoiceId}-keep`,
                        text: "Manter",
                      },
                    ]}
                  />
                </TableItem>
              </tr>
            ))}
          </TableBody>
        </Table>
      </Modal>
    </>
  );
};

Subscription.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  submitRef: PropTypes.object.isRequired,
  types: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  billingRanges: PropTypes.object.isRequired,
  isMEI: PropTypes.bool.isRequired,
  handleSetIsMEI: PropTypes.func.isRequired,
  subscriptionPlans: PropTypes.array.isRequired,
  planValue: PropTypes.number,
  generalTaxes: PropTypes.array.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  setPlanValue: PropTypes.func.isRequired,
  numberProlabore: PropTypes.number.isRequired,
  numberEmployees: PropTypes.number.isRequired,
  oldIdRange: PropTypes.number,
  globalSetting: PropTypes.object,
  activeSubscriptionId: PropTypes.number,
};

Subscription.defaultProps = {
  oldIdRange: null,
  planValue: 0,
};

export default memo(Subscription);
