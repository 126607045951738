import React, { useState } from "react";
import { Form } from "components/core/Form";
import { Flex } from "components/core/Grid";
import { Spinner } from "../WithSpinner/styled";
import { ModalActions } from "components/core/Modal/styled";
import { SelectWithDot } from "../SelectForm";
import Modal from "components/core/Modal";
import Button from "components/core/Button";
import InputForm from "components/core/InputForm";
import toast from "react-hot-toast";
import api from "services/api";

const ModalCreateService = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false);
  const validate = (data) => {
    if (data.name == "") {
      toast.error("O campo nome é obrigatório!", { position: "top-right" });
      return false;
    }
    if (data.status == -1) {
      toast.error("O campo status é obrigatório!", { position: "top-right" });
      return false;
    };
    return true;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    if (!validate(data)) {
      setLoading(false);
      return;
    }
    try {
      await api.post("/services", data);
      toast.success("Serviço criado com sucesso!", { position: "top-right" });
      onClose();
    } catch (error) {
      toast.error("Ocorreu um erro ao criar o serviço", { position: "top-right" });
    } finally {
      setLoading(false);
    }
  }
  return (
    <Modal
      title="Criar Serviço"
      confirmLabel="Criar"
      onCloseModal={() => onClose()}
      showModal={open}
      textAlign="text-left"
      showActions={false}
    >
      <Flex verticalAlign="center">
        <Form onSubmit={handleSubmit}>
          <InputForm
            type="text"
            name="name"
            label="Nome"
            placeholder="Digite um nome..."
          />
           <SelectWithDot
            id="status"
            name="status"
            label="Status"
            mb="3.2rem"
            options={[
              { value: -1, text: "Selecione um Status" },
              { value: 1, text: "Ativo" },
              { value: 0, text: "Inativo" },
            ]}
          />
          <ModalActions>
            <Button variant="outline" handleClick={onClose}>
              Cancelar
            </Button>
            <Button type="submit" disabled={loading}>
              {
                loading ? (
                  <Spinner margin={'auto'} width={'2rem'} height={'2rem'}/>
                ) : "Criar"
              }
            </Button>
          </ModalActions>
        </Form>
      </Flex>
    </Modal>
  )
}

export default ModalCreateService;