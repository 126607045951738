const columns = [
  { slug: "id", title: "Código" },
  { slug: "name", title: "Nome" },
  { slug: "description", title: "Descrição" },
  { slug: "service", title: "Serviço" },
  { slug: "event", title: "Evento" },
  { slug: "url", title: "Link" },
  { slug: "model", title: "Model" },
  { slug: "status", title: "Status"  },
  { slug: "createdAt", title: "Data de criação" },
  { slug: "updatedAt", title: "Data de edição" },
];
export default columns;