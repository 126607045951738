import React, { useEffect, useRef, useState } from "react";
import Pagination from "components/core/Pagination";
import PropTypes from "prop-types";
import { formatPtBrDate } from "helpers";
import {
  Table,
  TableHeader,
  TableBody,
  TableItem,
  RenderHeaders,
} from "components/core/Table";
import SwitchStatus from "components/core/SwitchStatus";
import { useReload } from "hooks";

const DataTable = ({ params, columns }) => {
  const data = params?.data;
  const firstRenderRef = useRef(true);
  const { reloadPage, setPage, setPerPage, reset, filters } = useReload();
  const [actualPage, setActualPage] = useState(params.currentPage);
  
  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      filters <= 0 && reset();
      return;
    }

    setPage(actualPage);
    setPerPage(params.perPage);
    reloadPage();
  }, [actualPage]);

  const handleChange = (e) => {
    data[e.index].status = e.data.status;
  }

  return (
    <>
      <Table>
        <TableHeader>
          <RenderHeaders
            headers={columns}
            order={"desc"}
          />
        </TableHeader>
        <TableBody>
            {
              data && data.length > 0 ? (data.map((d, index) => {
                return (
                  <tr key={d.id}>
                    <TableItem>
                      {d.id}
                    </TableItem>
                    <TableItem>
                      {d.name}
                    </TableItem>
                    <TableItem>
                      {d.description}
                    </TableItem>
                    <TableItem>
                      {d.service}
                    </TableItem>
                    <TableItem>
                      {d.event}
                    </TableItem>
                    <TableItem>
                      {d.url}
                    </TableItem>
                    <TableItem>
                      {d.model ? d.model : '-'}
                    </TableItem>
                    <TableItem>
                      <SwitchStatus data={d} handleChange={handleChange} index={index} />
                    </TableItem>
                    <TableItem>
                      {formatPtBrDate(d.createdAt)}
                    </TableItem>
                    <TableItem>
                      {formatPtBrDate(d.updatedAt)}
                    </TableItem>
                  </tr>
                )
              })) : (
                <tr style={{ textAlign: "center" }}>
                  <td style={{ padding: "20px", fontSize: "16px" }} colSpan={9}>Nenhum registro encontrado</td>
                </tr>
              )
            }
        </TableBody>
      </Table>
      <Pagination
        perPage={params.perPage}
        totalItems={params.totalItems}
        actualPage={actualPage}
        lastPage={params.totalPages}
        setPage={setActualPage}
      />
    </>
  );
};

DataTable.propTypes = {
  emitters: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  handleChangePaginate: PropTypes.func.isRequired,
  view: PropTypes.bool.isRequired,
};

DataTable.defaultProps = {
  emitters: [],
  total: 0,
  totalPages: 0,
  page: 1,
  perPage: 0,
};

export default DataTable;