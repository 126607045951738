import roles, {
  ROLE_MASTER,
  ROLE_CLIENT_OBVIA,
  ROLE_ACCOUNTANT,
  ROLE_MARKETING,
} from "utils/constants/roles";
import * as Icons from "components/core/SideBar/icons";
import {
  MdWebhook,
  MdOutlineDisplaySettings,
  MdOutlineTask,
} from "react-icons/md";

const BASE = "";
const USERS = "users";
const EMITTERS = "emitters";
const BATCHES = "batches";
const XML = "xml";
const CITIES = "cities";
const CLIENTS = "clients";
const COUPONS = "coupons";
const PAYMENT_GATEWAYS = "payment-gateways";
const FINANCIAL = "financial";
const SUBSCRIPTIONS = "subscriptions";
const INVOICES = "invoices";
const DOWNLOAD = "download";
const GLOBAL_SETTINGS = "global-settings";
const INSTALLMENTS = "installments";
const REPORTS = "reports";
const REPORTSFILES = "reportsfiles";
const REPORTSPAYMENTS = "reports-payments";
const DOCS = "docs";
const SENT = "sent";
const RECEIVED = "received";
const PROFILE = "profile";
const INVOICE = "invoices";
const LOGIN = "login";
const TAXES = "taxes";
const NOTIFICATIONS = "notifications";
const CUSTOMER_SERVICE = "customer-service";
const DETAILS = "details";
const EDIT = "edit";
const ADD = "add";
const DIGITAL_AUTHORIZATION = "authorization";
const MUNICIPAL_SERVICES = "municipal-services";
const PRODUCTS = "products";
const NCM_CATEGORY = "ncm-category";
const NCM_GLOBALS = "ncm-globals";
const NCM = "ncm";
const CFOP = "cfop";
const CST = "cst";
const ID = ":id";
const EMITTER_ID = ":emitterId";
const USER_ID = ":userId";
const Identify = ":Identify";
const FILENAME = ":filename";
const COMERCIAL = "comercial";
const INTER_BANK = "inter";
const PARAMS = "params";
const CERTIFICATES = "certificates";
const WEBHOOKS = "webhooks";
const TASKS = "tasks";

const ROUTES = {
  app: {
    path: `${BASE}/`,
  },
  login: {
    path: `${BASE}/${LOGIN}`,
  },
  notifications: {
    show: true,
    roles: [ROLE_MASTER, ROLE_ACCOUNTANT],
    path: `${BASE}/${NOTIFICATIONS}`,
    i18nKey: "notifications",
    Icon: Icons.Notification,
  },
  profile: {
    path: `${BASE}/${PROFILE}`,
    i18nKey: "profile",
    Icon: Icons.Avatar,
    children: {
      edit: {
        path: `${BASE}/${PROFILE}/${EDIT}`,
      },
    },
  },
  emitters: {
    show: true,
    path: `${BASE}/${EMITTERS}`,
    i18nKey: "emitters",
    Icon: Icons.Group,
    roles: [ROLE_MASTER, ROLE_ACCOUNTANT],
    children: {
      register: {
        path: `${BASE}/${EMITTERS}/register`,
      },
      details: {
        path: `${BASE}/${EMITTERS}/${ID}/${DETAILS}`,
      },
      edit: {
        path: `${BASE}/${EMITTERS}/${ID}/${EDIT}`,
      },
      subscription: {
        path: `${BASE}/${EMITTERS}/${ID}/${EDIT}/subscription`,
      },
    },
  },
  customerService: {
    show: true,
    roles: [ROLE_MASTER, ROLE_ACCOUNTANT],
    path: `${BASE}/${CUSTOMER_SERVICE}/chat`,
    i18nKey: "Atendimento",
    Icon: Icons.Phone,
  },
  users: {
    show: true,
    path: `${BASE}/${USERS}`,
    i18nKey: "users",
    roles: [ROLE_MASTER],
    Icon: Icons.Users,
    children: {
      register: {
        path: `${BASE}/${USERS}/register`,
      },
      details: {
        path: `${BASE}/${USERS}/${ID}/${DETAILS}`,
      },
      edit: {
        path: `${BASE}/${USERS}/${ID}/${EDIT}`,
      },
    },
  },
  clients: {
    emitterMenu: true,
    path: `${BASE}/${EMITTERS}/${EMITTER_ID}/${CLIENTS}`,
    i18nKey: "takers",
    Icon: Icons.Client,
    children: {
      details: {
        path: `${BASE}/${EMITTERS}/${EMITTER_ID}/${CLIENTS}/${ID}/${DETAILS}`,
      },
      edit: {
        path: `${BASE}/${EMITTERS}/${EMITTER_ID}/${CLIENTS}/${ID}/${EDIT}`,
      },
    },
  },
  invoices: {
    emitterMenu: false,
    path: `${BASE}/${EMITTERS}/${EMITTER_ID}/${INVOICE}`,
    i18nKey: "manageInvoices",
    Icon: Icons.Doc,
    children: {
      details: {
        path: `${BASE}/${EMITTERS}/${EMITTER_ID}/${INVOICE}/${ID}/${DETAILS}`,
      },
      rps: {
        path: `${BASE}/${EMITTERS}/${EMITTER_ID}/${INVOICE}/rps`,
      },
    },
  },
  documents: {
    emitterMenu: true,
    children: {
      sent: {
        emitterMenu: true,
        path: `${BASE}/${EMITTERS}/${USER_ID}/${DOCS}/${SENT}/:documentId?`,
        i18nKey: "uploadDocuments",
        Icon: Icons.Upload,
      },
      received: {
        emitterMenu: true,
        path: `${BASE}/${EMITTERS}/${USER_ID}/${DOCS}/${RECEIVED}/:documentId?`,
        i18nKey: "downloadDocuments",
        Icon: Icons.Download,
      },
    },
  },
  municipalServices: {
    emitterMenu: false,
    path: `${BASE}/${EMITTERS}/${EMITTER_ID}/${MUNICIPAL_SERVICES}`,
    i18nKey: "municipalServices",
    Icon: Icons.Building,
    children: {
      details: {
        path: `${BASE}/${EMITTERS}/${EMITTER_ID}/${MUNICIPAL_SERVICES}/${ID}/${DETAILS}`,
      },
      edit: {
        path: `${BASE}/${EMITTERS}/${EMITTER_ID}/${MUNICIPAL_SERVICES}/${ID}/${EDIT}`,
      },
    },
  },
  digitalAuthorization: {
    emitterMenu: false,
    path: `${BASE}/${EMITTERS}/${EMITTER_ID}/${DIGITAL_AUTHORIZATION}`,
    i18nKey: "digitalAuthorization",
    Icon: Icons.Pen,
  },
  taxes: {
    manageMenu: true,
    path: `${BASE}/${TAXES}`,
    i18nKey: "Gerenciar Planos",
    Icon: Icons.Dollar,
    children: {
      general: {
        path: `${BASE}/${TAXES}/general/${EDIT}`,
        roles: [ROLE_MASTER],
      },
      mei: {
        path: `${BASE}/${TAXES}/mei/${EDIT}`,
        roles: [ROLE_MASTER],
      },
      others: {
        path: `${BASE}/${TAXES}/others/${ID}/${EDIT}`,
        roles: [ROLE_MASTER],
      },
    },
  },
  coupons: {
    show: true,
    manageMenu: true,
    roles: [ROLE_MARKETING],
    path: `${BASE}/${COUPONS}`,
    i18nKey: "coupons",
    Icon: Icons.Coupom,
    children: {
      edit: {
        path: `${BASE}/${COUPONS}/${ID}/${EDIT}`,
      },
      register: {
        path: `${BASE}/${COUPONS}/register`,
      },
      details: {
        path: `${BASE}/${COUPONS}/${ID}/${DETAILS}`,
      },
    },
  },
  paymentGateways: {
    manageMenu: true,
    roles: [ROLE_MASTER],
    path: `${BASE}/${PAYMENT_GATEWAYS}`,
    i18nKey: "Payment Gateways",
    Icon: Icons.PaymentGateway,
    children: {
      edit: {
        path: `${BASE}/${PAYMENT_GATEWAYS}/${ID}/${EDIT}`,
      },
      details: {
        path: `${BASE}/${PAYMENT_GATEWAYS}/${ID}/${DETAILS}`,
      },
    },
  },
  ncmGlobals: {
    manageMenu: true,
    path: `${BASE}/${NCM_GLOBALS}`,
    i18nKey: "NCM Globais",
    Icon: Icons.NCM,
    children: {
      edit: {
        path: `${BASE}/${NCM_GLOBALS}/${ID}/${EDIT}`,
      },
      register: {
        children: {
          ncmCategory: {
            path: `${BASE}/${NCM_CATEGORY}/register`,
          },
          ncmGlobals: {
            path: `${BASE}/${NCM_GLOBALS}/register`,
          },
        },
      },
      details: {
        path: `${BASE}/${NCM_GLOBALS}/${ID}/${DETAILS}`,
      },
    },
  },
  cities: {
    manageMenu: true,
    path: `${BASE}/${CITIES}`,
    i18nKey: "cities",
    Icon: Icons.Cities,
  },
  batches: {
    show: true,
    manageMenu: false,
    roles: [ROLE_CLIENT_OBVIA],
    path: `${BASE}/${BATCHES}`,
    i18nKey: "batches",
    Icon: Icons.Batches,
    children: {
      confirm: {
        i18nKey: "newBatch",
        path: `${BASE}/${BATCHES}/confirm`,
        children: {
          editEnterprise: {
            path: `${BASE}/${BATCHES}/confirm/editEnterprise/${ID}`,
          },
        },
      },
      company: {
        i18nKey: "invoiceIssued",
        path: `${BASE}/${BATCHES}/company/${ID}`,
      },
      details: {
        path: `${BASE}/${BATCHES}/details/${Identify}/${ID}`,
      },
    },
  },
  xml: {
    path: `${BASE}/${XML}`,
    manageMenu: true,
    i18nKey: "xmlScreen",
    Icon: Icons.Export,
  },
  recoverPassword: {
    show: false,
    path: `${BASE}/${LOGIN}/recover`,
    children: {
      token: {
        path: `${BASE}/${LOGIN}/recover/:token`,
      },
    },
  },
  payment: {
    show: false,
    path: `${BASE}/payment/:token`,
  },
  openApp: {
    show: false,
    path: `${BASE}/app/:page`,
  },
  invoicePayment: {
    show: false,
    path: `${BASE}/invoice-payment/:token`,
  },
  invoicePaymentOld: {
    show: false,
    path: `${BASE}/invoice-payment-old/:token`,
  },
  congratulations: {
    show: false,
    path: `${BASE}/congratulations`,
  },
  landingNewUser: {
    show: false,
    path: `${BASE}/cadastre-se`,
  },

  newUser: {
    show: false,
    path: `${BASE}/cadastro`,
    children: {
      useTerms: {
        path: `${BASE}/cadastro/termos-de-uso`,
      },
      consentTerms: {
        path: `${BASE}/cadastro/termo-de-consentimento`,
      },
    },
  },
  invoicesAcc: {
    accordionMenu: true,
    i18nKey: "manageInvoices",
    Icon: Icons.Doc,
    children: {
      issued: {
        path: `${BASE}/${EMITTERS}/${EMITTER_ID}/${INVOICE}`,
        i18nKey: "notesIssued",
        Icon: Icons.DocCheck,
      },
      digitalAuthorization: {
        path: `${BASE}/${EMITTERS}/${EMITTER_ID}/${DIGITAL_AUTHORIZATION}`,
        i18nKey: "digitalAuthorization",
        Icon: Icons.Pen,
      },
      rpsseries: {
        path: `${BASE}/${EMITTERS}/${EMITTER_ID}/${INVOICE}/rps`,
        i18nKey: "rpsSerie",
        Icon: Icons.Doc,
      },
      municipalServices: {
        emitterMenu: true,
        municipalServicesMenu: true,
        path: `${BASE}/${EMITTERS}/${EMITTER_ID}/${MUNICIPAL_SERVICES}`,
        i18nKey: "municipalServices",
        Icon: Icons.Building,
      },
    },
  },
  params: {
    manageMenu: true,
    adminMenu: true,
    accordionMenu: true,
    i18nKey: "params",
    Icon: Icons.FinancialSettings,
    roles: [ROLE_MASTER],
    children: {
      paramsEmitters: {
        path: `${BASE}/${PARAMS}/${EMITTERS}`,
        i18nKey: "emitter",
        Icon: Icons.User,
        roles: [ROLE_MASTER],
      },
      lists: {
        children: {
          activityGroups: {
            path: `${BASE}/${PARAMS}/${EMITTERS}/activity-groups`,
          },
          sources: {
            path: `${BASE}/${PARAMS}/${EMITTERS}/sources`,
          },
          responsibleSale: {
            path: `${BASE}/${PARAMS}/${EMITTERS}/responsibles-sale`,
          },
          partners: {
            path: `${BASE}/${PARAMS}/${EMITTERS}/partners`,
          },
        },
      },
      accountantCertificates: {
        path: `${BASE}/${PARAMS}/${CERTIFICATES}`,
        i18nKey: "accountantCertificates",
        Icon: Icons.Export,
        roles: [ROLE_MASTER, ROLE_ACCOUNTANT],
      },
    },
  },
  financial: {
    manageMenu: true,
    accordionMenu: true,
    i18nKey: "Financial",
    Icon: Icons.Financial,
    roles: [ROLE_MASTER],
    children: {
      installments: {
        path: `${BASE}/${FINANCIAL}/${GLOBAL_SETTINGS}/${INSTALLMENTS}/`,
        accordionMenu: true,
        adminMenu: true,
        i18nKey: "Installments",
        Icon: Icons.FinancialSettings,
        roles: [ROLE_MASTER],
        children: {
          add: {
            path: `${BASE}/${FINANCIAL}/${GLOBAL_SETTINGS}/${INSTALLMENTS}/${ADD}/`,
          },
          details: {
            path: `${BASE}/${FINANCIAL}/${GLOBAL_SETTINGS}/${INSTALLMENTS}/${ID}/${DETAILS}/`,
          },
          edit: {
            path: `${BASE}/${FINANCIAL}/${GLOBAL_SETTINGS}/${INSTALLMENTS}/${ID}/${EDIT}/`,
          },
        },
        // add: {
        //   path: `${BASE}/${FINANCIAL}/${GLOBAL_SETTINGS}/${ADD}`,
        // },
        // details: {
        //   path: `${BASE}/${FINANCIAL}/${GLOBAL_SETTINGS}/${ID}/${DETAILS}`,
        // },
        // edit: {
        //   path: `${BASE}/${FINANCIAL}/${GLOBAL_SETTINGS}/${ID}/${EDIT}`,
        // },
      },
      subscriptions: {
        path: `${BASE}/${FINANCIAL}/${SUBSCRIPTIONS}`,
        adminMenu: true,
        i18nKey: "Subscriptions",
        Icon: Icons.Subscriptions,
        roles: [ROLE_MASTER],
        children: {
          edit: {
            path: `${BASE}/${FINANCIAL}/${SUBSCRIPTIONS}/${ID}/${EDIT}`,
          },
          details: {
            path: `${BASE}/${FINANCIAL}/${SUBSCRIPTIONS}/${ID}/${DETAILS}`,
          },
        },
      },
      nextBillings: {
        path: `${BASE}/${FINANCIAL}/${SUBSCRIPTIONS}/next-billings`,
        adminMenu: true,
        i18nKey: "Next Billings",
        Icon: Icons.Subscriptions,
        roles: [ROLE_MASTER],
        children: {
          details: {
            path: `${BASE}/${FINANCIAL}/${SUBSCRIPTIONS}/${ID}/next-billings/${DETAILS}`,
          },
        },
      },
      invoices: {
        path: `${BASE}/${FINANCIAL}/${INVOICES}`,
        adminMenu: true,
        i18nKey: "Invoices",
        Icon: Icons.Invoice,
        roles: [ROLE_MASTER],
        children: {
          // edit: {
          //   path: `${BASE}/${SUBSCRIPTIONS}/${ID}/${EDIT}`,
          // },
          details: {
            path: `${BASE}/${FINANCIAL}/${INVOICES}/${ID}/${DETAILS}`,
          },
          downloadList: {
            path: `${BASE}/${FINANCIAL}/${INVOICES}/${DOWNLOAD}/${FILENAME}`,
          },
        },
      },
      nextCharges: {
        path: `${BASE}/${FINANCIAL}/${INVOICES}/next-charges`,
        adminMenu: true,
        i18nKey: "Next Charges",
        Icon: Icons.Invoice,
        roles: [ROLE_MASTER],
      },
      emitterSubscriptions: {
        emitterMenu: true,
        path: `${BASE}/${EMITTERS}/${EMITTER_ID}/${FINANCIAL}/${SUBSCRIPTIONS}`,
        i18nKey: "Subscriptions",
        Icon: Icons.Subscriptions,
        roles: [ROLE_MASTER],
        children: {
          // edit: {
          //   path: `${BASE}/${SUBSCRIPTIONS}/${ID}/${EDIT}`,
          // },
          // details: {
          //   path: `${BASE}/${SUBSCRIPTIONS}/${ID}/${DETAILS}`,
          // },
        },
      },
    },
  },
  reports: {
    manageMenu: true,
    accordionMenu: true,
    i18nKey: "Reports",
    Icon: Icons.Reports,
    roles: [ROLE_MASTER],
    children: {
      reportsfiles: {
        path: `${BASE}/${REPORTS}/${REPORTSFILES}`,
        i18nKey: "Arquivos",
        Icon: Icons.Upload,
        roles: [ROLE_MASTER],
        children: {
          // edit: {
          //   path: `${BASE}/${SUBSCRIPTIONS}/${ID}/${EDIT}`,
          // },
          // details: {
          //   path: `${BASE}/${SUBSCRIPTIONS}/${ID}/${DETAILS}`,
          // },
        },
      },
      reportPayments: {
        adminMenu: true,
        path: `${BASE}/${REPORTS}/${REPORTSPAYMENTS}`,
        i18nKey: "reportsPayments",
        Icon: Icons.Invoice,
        roles: [ROLE_MASTER],
      },
    },
  },
  Comercial: {
    manageMenu: true,
    adminMenu: true,
    accordionMenu: true,
    i18nKey: "Comercial",
    Icon: Icons.Dollar,
    roles: [ROLE_MASTER],
    children: {
      usersBankInter: {
        path: `${BASE}/${COMERCIAL}/${INTER_BANK}`,
        i18nKey: "Leads Banco Inter",
        Icon: Icons.Client,
        roles: [ROLE_MASTER],
      },
    },
  },
  products: {
    accordionMenu: true,
    productMenu: true,
    i18nKey: "manageProducts",
    Icon: Icons.Box,
    children: {
      productsList: {
        path: `${BASE}/${EMITTERS}/${EMITTER_ID}/${PRODUCTS}`,
        i18nKey: "productsList",
        Icon: Icons.Clipboard,
        children: {
          edit: {
            path: `${BASE}/${EMITTERS}/${EMITTER_ID}/${PRODUCTS}/${ID}/${EDIT}`,
          },
          register: {
            path: `${BASE}/${EMITTERS}/${EMITTER_ID}/${PRODUCTS}/register`,
          },
          details: {
            path: `${BASE}/${EMITTERS}/${EMITTER_ID}/${PRODUCTS}/${ID}/${DETAILS}`,
          },
        },
      },
      cfop: {
        path: `${BASE}/${EMITTERS}/${EMITTER_ID}/${CFOP}`,
        i18nKey: "cfop",
        Icon: Icons.CFOP,
      },
      cst: {
        path: `${BASE}/${EMITTERS}/${EMITTER_ID}/${CST}`,
        i18nKey: "csosn",
        Icon: Icons.CFOP,
      },
      ncm: {
        path: `${BASE}/${EMITTERS}/${EMITTER_ID}/${NCM}`,
        i18nKey: "ncmCategory",
        Icon: Icons.NCM,
      },
    },
  },
  development: {
    manageMenu: true,
    adminMenu: true,
    accordionMenu: true,
    i18nKey: "Integrações",
    Icon: MdOutlineDisplaySettings,
    children: {
      webhooks: {
        path: `${BASE}/${WEBHOOKS}`,
        i18nKey: "Webhooks",
        Icon: MdWebhook,
      },
    },
  },
  tasks: {
    manageMenu: true,
    path: `${BASE}/${TASKS}`,
    i18nKey: "Controle de Tarefas",
    Icon: MdOutlineTask,
    roles: [ROLE_MASTER],
  },
};

const replaceId = (route, id) => route.replace(/:id\??/gm, id);
const replaceToken = (route, id) => route.replace(/:token\??/gm, id);
const replaceFilename = (route, filename) => route.replace(/:filename\??/gm, filename);
const replaceEmitterId = (route, emitterId) => route.replace(/:emitterId\??/gm, emitterId);
const replaceUserId = (route, userId) => route.replace(/:userId\??/gm, userId);
const replaceDocumentId = (route, userId) => route.replace(/:documentId\??/gm, userId);

const LINKS = {
  app: ROUTES.app.path,
  login: ROUTES.login.path,
  userList: ROUTES.users.path,
  userRegister: ROUTES.users.children.register.path,
  userDetails: (id) => replaceId(ROUTES.users.children.details.path, id),
  userEdit: (id) => replaceId(ROUTES.users.children.edit.path, id),
  clientDetails: (emitterId, id) =>
    replaceId(replaceEmitterId(ROUTES.clients.children.details.path, emitterId), id),
  documentsSent: (userId, id) =>
    replaceDocumentId(replaceUserId(ROUTES.documents.children.sent.path, userId), id),
  documentsReceived: (userId, id) =>
    replaceDocumentId(replaceUserId(ROUTES.documents.children.received.path, userId), id),
  couponsList: ROUTES.coupons.path,
  couponsRegister: ROUTES.coupons.children.register.path,
  couponsDetails: (id) => replaceId(ROUTES.coupons.children.details.path, id),
  couponsEdit: (id) => replaceId(ROUTES.coupons.children.edit.path, id),
  customerService: (id) => replaceId(ROUTES.customerService.path, id),
  cities: ROUTES.cities.path,
  batches: ROUTES.batches.path,
  batchesConfirm: ROUTES.batches.children.confirm.path,
  batchesConfirmEditEnterprise: (id) =>
    replaceId(ROUTES.batches.children.confirm.children.editEnterprise.path, id),
  batchesCompany: (id) => replaceId(ROUTES.batches.children.company.path, id),
  emitter: ROUTES.emitters.path,
  emitterRegister: ROUTES.emitters.children.register.path,
  emitterDetails: (id) => replaceId(ROUTES.emitters.children.details.path, id),
  emitterEdit: (id) => replaceId(ROUTES.emitters.children.edit.path, id),
  emitterSubscriptionEdit: (id) => replaceId(ROUTES.emitters.children.subscription.path, id),
  profile: ROUTES.profile.path,
  profileEdit: ROUTES.profile.children.edit.path,
  invoiceDetails: (emitterId, id) =>
    replaceId(replaceEmitterId(ROUTES.invoices.children.details.path, emitterId), id),
  invoiceRPS: (emitterId) => replaceEmitterId(ROUTES.invoices.children.rps.path, emitterId),
  generalTax: ROUTES.taxes.children.general.path,
  editOtherTaxes: (id) => replaceId(ROUTES.taxes.children.others.path, id),
  ncmGlobalsList: ROUTES.ncmGlobals.path,
  ncmGlobalsRegister: ROUTES.ncmGlobals.children.register.children.ncmGlobals.path,
  ncmCategoryRegister: ROUTES.ncmGlobals.children.register.children.ncmCategory.path,
  ncmGlobalsDetails: (id) => replaceId(ROUTES.ncmGlobals.children.details.path, id),
  ncmGlobalsEdit: (id) => replaceId(ROUTES.ncmGlobals.children.edit.path, id),
  municipalServicesEdit: (emitterId, id) =>
    replaceId(replaceEmitterId(ROUTES.municipalServices.children.edit.path, emitterId), id),
  municipalServicesDetails: (emitterId, id) =>
    replaceId(replaceEmitterId(ROUTES.municipalServices.children.details.path, emitterId), id),

  productsRegister: (emitterId, id) =>
    replaceId(
      replaceEmitterId(ROUTES.products.children.productsList.children.register.path, emitterId),
      id,
    ),
  productsEdit: (emitterId, id) =>
    replaceId(
      replaceEmitterId(ROUTES.products.children.productsList.children.edit.path, emitterId),
      id,
    ),
  productsList: (emitterId, id) =>
    replaceId(replaceEmitterId(ROUTES.products.children.productsList.path, emitterId), id),

  productsDetails: (emitterId, id) =>
    replaceId(
      replaceEmitterId(ROUTES.products.children.productsList.children.details.path, emitterId),
      id,
    ),

  ncmEdit: (emitterId, id) =>
    replaceId(replaceEmitterId(ROUTES.products.children.edit.path, emitterId), id),
  ncmDetails: (emitterId, id) =>
    replaceId(replaceEmitterId(ROUTES.products.children.ncm.path, emitterId), id),

  cfopEdit: (emitterId, id) =>
    replaceId(replaceEmitterId(ROUTES.products.children.edit.path, emitterId), id),
  cfopDetails: (emitterId, id) =>
    replaceId(replaceEmitterId(ROUTES.products.children.cfop.path, emitterId), id),

  cstEdit: (emitterId, id) =>
    replaceId(replaceEmitterId(ROUTES.products.children.edit.path, emitterId), id),
  cstDetails: (emitterId, id) =>
    replaceId(replaceEmitterId(ROUTES.products.children.cst.path, emitterId), id),

  paymentGatewaysList: ROUTES.paymentGateways.path,
  paymentGatewaysDetails: (id) => replaceId(ROUTES.paymentGateways.children.details.path, id),
  paymentGatewaysEdit: (id) => replaceId(ROUTES.paymentGateways.children.edit.path, id),

  financial: {
    installments: {
      root: ROUTES.financial.children.installments.path,
      add: ROUTES.financial.children.installments.children.add.path,
      details: (id) => replaceId(ROUTES.financial.children.installments.children.details.path, id),
      edit: (id) => replaceId(ROUTES.financial.children.installments.children.edit.path, id),
    },
    invoices: {
      root: ROUTES.financial.children.invoices.path,
      details: (id) => replaceId(ROUTES.financial.children.invoices.children.details.path, id),
      download: (filename) =>
        replaceFilename(ROUTES.financial.children.invoices.children.downloadList.path, filename),
    },
    subscriptions: {
      root: ROUTES.financial.children.subscriptions.path,
      details: (id) => replaceId(ROUTES.financial.children.subscriptions.children.details.path, id),
      edit: (id) => replaceId(ROUTES.financial.children.subscriptions.children.edit.path, id),
    },
    nextBillings: {
      root: ROUTES.financial.children.nextBillings.path,
      details: (id) => replaceId(ROUTES.financial.children.nextBillings.children.details.path, id),
    },
  },
  paymentLink: (token) => replaceToken(ROUTES.payment.path, token),
  invoicePayment: (token) => replaceToken(ROUTES.invoicePayment.path, token),
  openApp: (token) => replaceToken(ROUTES.openApp.path, token),
  paramsEmiiterActivityGroups: ROUTES.params.children.lists.children.activityGroups.path,
  paramsEmiiterSources: ROUTES.params.children.lists.children.sources.path,
  paramsEmiiterResponsibleSale: ROUTES.params.children.lists.children.responsibleSale.path,
  paramsEmiiterPartner: ROUTES.params.children.lists.children.partners.path,
  accountantCertificates: ROUTES.params.children.accountantCertificates.path,
};

const routesNotificationDictionary = {
  newLead: ({ idEnterprise }) => LINKS.emitterDetails(idEnterprise),
  newEmitter: ({ idEnterprise }) => LINKS.emitterDetails(idEnterprise),
  sendCertificate: ROUTES.documents.children.received.path,
  newInvoice: ({ idInvoice, idEnterprise }) => LINKS.invoiceDetails(idEnterprise, idInvoice),
  newMessage: ({ idUser }) => LINKS.customerService(idUser),
  uploadByClient: ({ idUser, idDocument }) => LINKS.documentsReceived(idUser, idDocument),
  paymentStatusChange: ({ idEnterprise }) => LINKS.emitterDetails(idEnterprise),
  certificateStatus: ({ idEnterprise }) => LINKS.emitterDetails(idEnterprise),
  uploadByAccountant: ({ idUser, idDocument }) => LINKS.documentsSent(idUser, idDocument),
  updateCertificate: ROUTES.documents.children.received.path,
};

const routeByNotificationKey = (notificationKey, emitter) => {
  const routePath = routesNotificationDictionary[notificationKey];
  const { idEnterprise } = emitter;

  if (typeof routePath === "function") {
    return routePath(emitter);
  }

  return routePath ?? LINKS.emitterDetails(idEnterprise);
};

const PDFSURLS = {
  useTerms:
    "https://firebasestorage.googleapis.com/v0/b/obvia-1089b.appspot.com/o/Termo%20de%20uso%20-%20Obvia.pdf?alt=media&token=16038cf8-fb7f-4820-a8b0-04dd9696f95e",
  consentTerms:
    "https://firebasestorage.googleapis.com/v0/b/obvia-1089b.appspot.com/o/Termo%20de%20Consentimento%20-%20Obvia.pdf?alt=media&token=d8340494-c073-4ffe-a13d-8513d6f44c07",
};

const urls = { ROUTES, LINKS, PDFSURLS };

export { urls, ROUTES, LINKS, PDFSURLS, routeByNotificationKey };
